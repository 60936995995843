import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';

import { GameMode } from '../../consts';
import { setSlotConfig } from '../../gql/cache';
import { IConfig } from '../../gql/d';
import { gambleGql } from '../../gql/mutation';
import { configGql } from '../../gql/query';
import { EventTypes, eventManager } from '../../slotMachine/config';

export const Gamble: React.FC = () => {
  const [showPopup, setShowPopup] = useState(false);
  const { data: configData } = useQuery<IConfig>(configGql);

  const [gamble] = useMutation(gambleGql, {
    onError(error) {
      console.log(error);
    },
    async onCompleted(_data) {
      eventManager.emit(EventTypes.CHANGE_MODE, {
        mode: GameMode.FISH_GAMBLE,
        reelSetId: '',
        reelPositions: [0, 0, 0, 0],
        bgType: 'gamble',
      });

      eventManager.emit(EventTypes.GAMBLE_SPIN);

      //eventManager.emit(EventTypes.GAMBLE_TIMEOUT, false);
      //eventManager.emit(EventTypes.CHEAT_CLEAR);
      //const spinState = SlotMachine.getInstance().state;
      //if (spinState === SlotMachineState.SPIN) {
      //eventManager.emit(EventTypes.SET_IS_SLOTS_STOPPED, true);
      //}

      //TO DO : waiting start
      //      SlotMachine.getInstance().setGambleResult(data.selectOutcomes);
      //      if (SlotMachine.getInstance().isStopped) {
      //        const spinState = SlotMachine.getInstance().state;
      //        if (spinState != SlotMachineState.IDLE) {
      // SlotMachine.getInstance().spin(isTurboSpin);
      //        }
      //      }
    },
  });

  useEffect(() => {
    if (showPopup && !configData?.isTurboSpin) {
      setShowPopup(false);
    }
  }, [showPopup, configData?.isTurboSpin]);

  const onGambleSpin = useCallback(() => {
    //debugDisplay('onGambleSpin  setNextResult()', setNextResult(), 'setPlayer', setPlayer());
    //const { id: betId, coinAmount, coinValue, outcomes } = setNextResult()!.bet;
    //const outcomeIds = outcomes.find((d) => d.predicament.caseFn === setPlayer())!.id;
    //debugDisplay('onGambleSpin  outcomeIds', outcomeIds);
    //eventManager.emit(EventTypes.GAMBLE_TIMEOUT, true);
    // gamble({
    //   variables: {
    //     input: {
    //       betId: betId,
    //       coinAmount: coinAmount,
    //       coinValue: coinValue,
    //       outcomeIds: outcomeIds,
    //       slotId: setSlotConfig().slotId,
    //     },
    //   },
    // });
    // setShowPopup(true);

    gamble({
      variables: {
        input: {
          betId: 'betId',
          coinAmount: 100,
          coinValue: 1,
          outcomeIds: ['gambleOutcomeId'],
          slotId: setSlotConfig().slotId,
        },
      },
    });
  }, [gamble]);

  const onSpinButtonClick = useCallback(() => {
    //debugDisplay('onSpinButtonClick ', setGameMode());
    //if (setGameMode() != GameMode.BASE_GAME_GAMBLE) {
    //  return;
    //}
    onGambleSpin();
  }, [onGambleSpin]);

  useEffect(() => {
    eventManager.on(EventTypes.GAMBLE_SELECTED, () => {
      onSpinButtonClick();
    });

    return () => {
      eventManager.removeListener(EventTypes.GAMBLE_SELECTED);
    };
  }, [onSpinButtonClick]);

  return null;
};
