import * as PIXI from 'pixi.js';

import AutoResizeText, { AutoResizeTextStyle } from '../components/autoResizeText';
import ViewContainer from '../components/container';
import { layerWin } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import {
  EventTypes,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  WINLABEL_POS_X,
  WINLABEL_POS_Y,
  WinStages,
  eventManager,
  winLabelTextStyle,
} from '../config';

class WinLabelContainer extends ViewContainer {
  private bigWinText: AutoResizeText;
  private megaWinText: AutoResizeText;
  private greatWinText: AutoResizeText;
  private epicWinText: AutoResizeText;

  private winSpine: TickerSpine<'message_win'>;

  private backdrop: PIXI.Sprite;

  constructor() {
    super();

    this.backdrop = new PIXI.Sprite(PIXI.Texture.WHITE);
    this.backdrop.anchor.set(0.5);
    this.backdrop.tint = 0x000000;
    this.backdrop.alpha = 0.1;
    this.backdrop.width = 5000;
    this.backdrop.height = 5000;
    this.backdrop.x = SLOTS_CONTAINER_WIDTH / 2;
    this.backdrop.y = SLOTS_CONTAINER_HEIGHT / 2;
    this.backdrop.visible = false;
    this.addChild(this.backdrop);

    this.bigWinText = this.initWinText('BIG WIN', winLabelTextStyle);
    this.megaWinText = this.initWinText('MEGA WIN', winLabelTextStyle);
    this.greatWinText = this.initWinText('GREAT WIN', winLabelTextStyle);
    this.epicWinText = this.initWinText('EPIC WIN', winLabelTextStyle);

    this.winSpine = new TickerSpine('message_win');
    this.winSpine.skeleton.setSkinByName('default');
    this.winSpine.state.setAnimation(0, 'systemfont', true);
    this.winSpine.update(0);
    this.winSpine.visible = false;
    this.winSpine.position.x = WINLABEL_POS_X;
    this.winSpine.position.y = WINLABEL_POS_Y;
    this.addChild(this.winSpine);

    eventManager.addListener(EventTypes.SET_WIN_VISIBILITY, this.setWinState.bind(this));
    eventManager.addListener(EventTypes.SKIP_ALL_WIN_ANIMATIONS, this.skipAllWinAnimations.bind(this));
    eventManager.addListener(EventTypes.HANDLE_START_FADE_ANIMATION, (stage: number) => this.startFade(stage));
    eventManager.addListener(EventTypes.HANDLE_SKIP_FADE_ANIMATION, () => this.skipFadeAnimation());

    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));

    this.parentLayer = layerWin;
  }

  private initWinText(text: string, style: Partial<AutoResizeTextStyle>): AutoResizeText {
    const winText = new AutoResizeText(text, style);
    winText.anchor.set(0.5, 0.5);
    winText.position.set(WINLABEL_POS_X, WINLABEL_POS_Y);
    winText.alpha = 0;
    this.addChild(winText);
    return winText;
  }

  private setWinState(stage: WinStages) {
    switch (stage) {
      default:
      case WinStages.None:
      case WinStages.BaseWin:
        //this.winSpine.state.setEmptyAnimation(0);
        this.winSpine.visible = false;
        this.backdrop.visible = false;
        break;
      case WinStages.BigWin:
        this.winSpine.state.setAnimation(0, 'systemfont', true);
        this.winSpine.hackTextureBySlotName('blank', this.bigWinText.texture, this.bigWinText.texture.frame);
        this.winSpine.visible = true;
        this.backdrop.visible = true;
        break;
      case WinStages.MegaWin:
        this.winSpine.hackTextureBySlotName('blank', this.megaWinText.texture, this.megaWinText.texture.frame);
        this.winSpine.visible = true;
        this.backdrop.visible = true;
        break;
      case WinStages.GreatWin:
        this.winSpine.hackTextureBySlotName('blank', this.greatWinText.texture, this.greatWinText.texture.frame);
        break;
      case WinStages.EpicWin:
        this.winSpine.hackTextureBySlotName('blank', this.epicWinText.texture, this.epicWinText.texture.frame);
        break;
    }
  }

  private skipAllWinAnimations() {
    this.winSpine.visible = false;
    this.backdrop.visible = false;
    //this.winSpine.state.setEmptyAnimation(0);
  }

  private skipFadeAnimation() {}

  private startFade(_stage: WinStages) {}

  private applicationResize = (width: number, height: number): void => {
    if (height > width) {
      this.winSpine.position.y = WINLABEL_POS_Y;
    } else {
      this.winSpine.position.y = WINLABEL_POS_Y;
    }
  };
}

export default WinLabelContainer;
