export type AnnounceType = 'None' | 'low' | 'high' | 'phoenix';
export type AnnounceLotTblType = 'NONE' | 'LOW' | 'MID' | 'HI' | 'PH';
export const AnnounceConvTbl: AnnounceType[] = ['None', 'low', 'high', 'phoenix'];
export const AnnounceDataTbl: {
  bonus: boolean;
  snowCount: number[];
  lotTblType: AnnounceLotTblType;
  lotTbl: number[];
}[] = [
  {
    bonus: false,
    snowCount: [0, 1, 2, 3],
    lotTblType: 'NONE',
    lotTbl: [1000, 0, 0, 0],
  },
  {
    bonus: false,
    snowCount: [4, 5],
    lotTblType: 'LOW',
    lotTbl: [643, 357, 0, 0, 0],
  },
  {
    bonus: false,
    snowCount: [6, 7],
    lotTblType: 'MID',
    lotTbl: [300, 200, 500, 0],
  },
  {
    bonus: false,
    snowCount: [8, 9],
    lotTblType: 'HI',
    lotTbl: [250, 125, 125, 500],
  },
  {
    bonus: true,
    snowCount: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    lotTblType: 'PH',
    lotTbl: [400, 400, 200, 0],
  },
];
export const AnnounceReelRollingExternalDuration: Record<AnnounceType, number> = {
  None: 0,
  low: 2000,
  high: 2000,
  phoenix: 4000,
};

export const announcePositions = {
  anticipation: { x: 400, y: 400 },
};
