import { Event, TrackEntry } from '@pixi-spine/all-4.1';

import { FishKind } from '../../config';
import { SpineInterface } from '../../config/spine.generated';
import { GameMode } from '../../consts';
import { transitionToBaseGame } from '../../utils';
import Tween from '../animations/tween';
import ButtonContainer from '../components/buttonContainer';
import ViewContainer from '../components/container';
import { layerFishingGamble, layerUI } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import { EventTypes, eventManager } from '../config';

import { FishingLayout } from './config';
import { FishIcon } from './icon/fishIcon';

type TblRange = [number, number];
interface GambleData {
  id: number;
  result: 'WIN' | 'LOST';
  range: TblRange;
}

class FishGamble extends ViewContainer {
  //private rod: TickerSpine<'fg_indi'>;
  private sign: TickerSpine<'fg_indi'>;
  private effect: TickerSpine<'fg_indi'>;
  private meter: TickerSpine<'fg_indi_meter'>;
  private crimax_bg: TickerSpine<'fg_indi_crymax'>;
  private crimax_fishA: TickerSpine<'fg_indi_crymax'>;
  private crimax_fishB: TickerSpine<'fg_indi_crymax'>;
  private crimax_line: TickerSpine<'fg_indi_crymax'>;

  private miss: TickerSpine<'fg_indi'>;

  private fishRank: FishKind;
  private result: GambleData | null = null;

  private gambleStartBtn: ButtonContainer;

  private icon: FishIcon;

  private title: TickerSpine<'fg_indi'>;

  private stopEventName: string;

  private layout: 'landscape' | 'portrait';
  constructor() {
    super();

    this.fishRank = 'Rank1';
    this.layout = 'landscape';
    this.stopEventName = '';

    this.sign = new TickerSpine('fg_indi');
    this.sign.state.setAnimation(0, 'sgin', true);

    this.effect = new TickerSpine('fg_indi');

    this.miss = new TickerSpine('fg_indi');

    this.crimax_bg = new TickerSpine('fg_indi_crymax');
    this.crimax_fishA = new TickerSpine('fg_indi_crymax');
    this.crimax_fishB = new TickerSpine('fg_indi_crymax');
    this.crimax_line = new TickerSpine('fg_indi_crymax');

    this.meter = new TickerSpine('fg_indi_meter');
    this.meter.state.setAnimation(0, 'wait', true);

    this.icon = new FishIcon('Rank7');
    this.title = new TickerSpine('fg_indi');
    this.title.state.setAnimation(0, 'title', true);

    this.gambleStartBtn = new ButtonContainer(
      FishingLayout.Gamble.gambleStartBtnArea.x,
      FishingLayout.Gamble.gambleStartBtnArea.y,
      FishingLayout.Gamble.gambleStartBtnArea.w,
      FishingLayout.Gamble.gambleStartBtnArea.h,
      this.onGambleStartClick.bind(this),
    );

    const RankToCrimax: Record<
      FishKind,
      { a: SpineInterface['fg_indi_crymax']['animations']; b: SpineInterface['fg_indi_crymax']['animations'] }
    > = {
      Rank1: { a: 'a_pt01', b: 'b_pt01' },
      Rank2: { a: 'a_pt02', b: 'b_pt02' },
      Rank3: { a: 'a_pt03', b: 'b_pt03' },
      Rank4: { a: 'a_pt04', b: 'b_pt04' },
      Rank5: { a: 'a_pt05', b: 'b_pt05' },
      Rank6: { a: 'a_pt06', b: 'b_pt06' },
      Rank7: { a: 'a_pt07', b: 'b_pt07' },
    };
    const MeterActConvert: SpineInterface['fg_indi_meter']['animations'][] = [
      'hi',
      'mid2',
      'mid2',
      'mid2',
      'mid2',
      'mid1',
      'mid1',
      'mid1',
      'mid2',
      'mid2',
      'mid1',
      'mid1',
      'mid1',
      'low2',
      'low2',
      'low2',
      'low1',
      'low1',
      'low1',
      'low1',
    ];

    this.effect.state.addListener({
      complete: (entry: TrackEntry) => {
        if (entry.animation?.name === 'start') {
          this.crimax_bg.state.setAnimation(0, 'a_bg', false);
          this.crimax_fishA.state.setAnimation(0, RankToCrimax[this.fishRank].a, false);
          this.meter.state.setAnimation(
            0,
            this.result?.id != undefined ? MeterActConvert[this.result?.id!]! : 'low1',
            false,
          );
          this.crimax_bg.visible = true;
          this.crimax_fishA.visible = true;
          this.crimax_fishB.visible = false;
          this.effect.visible = false;
        }
      },
    });

    this.crimax_fishA.state.addListener({
      complete: (entry: TrackEntry) => {
        if (entry.animation?.name.includes('a_pt')) {
          this.crimax_bg.state.setAnimation(0, 'b_bg', false);
          this.crimax_fishB.state.setAnimation(0, RankToCrimax[this.fishRank].b, false);
          this.crimax_line.state.addAnimation(0, 'syutyu', true);
          this.crimax_bg.visible = true;
          this.crimax_fishA.visible = false;
          this.crimax_fishB.visible = true;
          this.crimax_line.visible = true;
        }
      },
    });

    this.meter.state.addListener({
      event: (_entry: TrackEntry, event: Event) => {
        if (event.data.name === this.stopEventName) {
          this.onEndAnimation();
        }
      },
      complete: (entry: TrackEntry) => {
        if (
          entry.animation?.name.includes('hi') ||
          entry.animation?.name.includes('mid') ||
          entry.animation?.name.includes('low')
        ) {
          this.onEndAnimation();
        }
      },
    });

    this.addChild(
      this.crimax_bg,
      this.crimax_fishA,
      this.crimax_fishB,
      this.crimax_line,
      this.effect,
      this.sign,
      this.meter,
      this.miss,
      this.icon,
      this.title,
      this.gambleStartBtn,
    );

    eventManager.on(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));

    eventManager.on(EventTypes.FISHING_GAMBLE_START_WAIT, this.start.bind(this));

    eventManager.on(EventTypes.FISHING_CHALLENGE_GOTCHA_END, this.onEndGotcha.bind(this));

    eventManager.on(EventTypes.GAMBLE_SELECTED, this.setFishKind.bind(this));

    this.parentLayer = layerFishingGamble;

    this.visible = false;

    this.gambleStartBtn.parentLayer = layerUI;

    //test
    //this.visible = true;
    //this.start('Rank3', 420, false);
  }

  private onGambleStartClick() {
    this.gambleStartBtn.interactive = false;
    this.icon!.visible = false;
    this.title.visible = false;

    this.effect.visible = true;
    this.effect.state.setAnimation(0, 'start', false);
    this.meter.state.setAnimation(0, 'wait', true);

    this.crimax_bg.visible = false;
    this.crimax_fishA.visible = false;
    this.crimax_fishB.visible = false;
    this.crimax_line.visible = false;

    eventManager.emit(EventTypes.FISHING_GAMBLE_START);
  }

  private setFishKind(fishKind?: FishKind) {
    this.fishRank = fishKind ?? 'Rank7';
    this.icon.setFishKind(this.fishRank);
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
    fishRank?: FishKind;
  }) {
    if (settings.mode === GameMode.FISH_GAMBLE) {
      this.meter.state.setAnimation(0, 'wait', true);
      this.icon.visible = true;
      this.crimax_bg.visible = false;
      this.crimax_fishA.visible = false;
      this.crimax_fishB.visible = false;
      this.crimax_line.visible = false;
      this.effect.visible = false;
      this.miss.visible = false;
      this.visible = true;
    } else {
      this.visible = false;
    }
  }

  private start(fishRank: FishKind, target: number, _result: boolean) {
    this.fishRank = fishRank;

    this.result = this.getIdByValue(target);
    this.visible = true;
    this.gambleStartBtn.interactive = true;
    this.stopEventName = `stop${this.result?.id! + 1}`;
  }
  private onEndAnimation() {
    const meterStop: SpineInterface['fg_indi_meter']['animations'] =
      this.result?.id! >= 9
        ? (`stop_${this.result?.id! + 1}` as SpineInterface['fg_indi_meter']['animations'])
        : (`stop_0${this.result?.id! + 1}` as SpineInterface['fg_indi_meter']['animations']);

    this.meter.state.setAnimation(0, meterStop, true);
    this.crimax_line.visible = false;

    if (this.result?.result === 'LOST') {
      this.miss.state.setAnimation(0, 'miss', true);
      this.miss.visible = true;

      const delay = Tween.createDelayAnimation(1500);
      delay.addOnComplete(() => {
        transitionToBaseGame(() => {
          this.visible = false;
        });
      });
      delay.start();
    } else {
      const delay = Tween.createDelayAnimation(1000);
      delay.addOnComplete(() => {
        eventManager.emit(EventTypes.FISHING_CHALLENGE_GOTCHA_START, this.fishRank);
      });
      delay.start();
    }
  }

  private onEndGotcha() {
    this.visible = false;
  }

  private getIdByValue(value: number): GambleData | null {
    const data: GambleData[] = [
      { id: 0, result: 'WIN', range: [0, 49] },
      { id: 1, result: 'WIN', range: [50, 99] },
      { id: 2, result: 'WIN', range: [100, 149] },
      { id: 3, result: 'WIN', range: [150, 199] },
      { id: 4, result: 'WIN', range: [200, 249] },
      { id: 5, result: 'WIN', range: [250, 299] },
      { id: 6, result: 'WIN', range: [300, 349] },
      { id: 7, result: 'WIN', range: [350, 399] },
      { id: 8, result: 'LOST', range: [400, 449] },
      { id: 9, result: 'LOST', range: [450, 499] },
      { id: 10, result: 'LOST', range: [500, 549] },
      { id: 11, result: 'LOST', range: [550, 599] },
      { id: 12, result: 'LOST', range: [600, 649] },
      { id: 13, result: 'LOST', range: [650, 699] },
      { id: 14, result: 'LOST', range: [700, 749] },
      { id: 15, result: 'LOST', range: [750, 799] },
      { id: 16, result: 'LOST', range: [800, 849] },
      { id: 17, result: 'LOST', range: [850, 899] },
      { id: 18, result: 'LOST', range: [900, 949] },
      { id: 19, result: 'LOST', range: [950, 999] },
    ];

    for (const item of data) {
      const [low, high] = item.range;
      if (value >= low && value <= high) {
        return item;
      }
    }
    return null;
  }

  private applicationResize = (width: number, height: number): void => {
    const layout = width > height ? FishingLayout.Gamble.landscape : FishingLayout.Gamble.portrait;
    const scale = layout.scale;

    this.sign.position.set(layout.Sign.x, layout.Sign.y);
    this.effect.position.set(layout.start.x, layout.start.y);
    this.meter.position.set(layout.meter.x, layout.meter.y);

    this.crimax_bg.position.set(layout.crimax_bg.x, layout.crimax_bg.y);
    this.crimax_fishA.position.set(layout.crimaxA_fish.x, layout.crimaxA_fish.y);
    this.crimax_fishB.position.set(layout.crimaxB_fish.x, layout.crimaxB_fish.y);
    this.crimax_line.position.set(layout.crimax_line.x, layout.crimax_line.y);

    this.miss.position.set(layout.miss.x, layout.miss.y);
    this.title.position.copyFrom(layout.title);
    this.icon.position.copyFrom(layout.icon);

    this.title.scale.set(scale.title);
    this.icon.scale.set(scale.icon);
    this.crimax_bg.scale.set(scale.icon);
    this.crimax_fishA.scale.set(scale.crimaxA);
    this.crimax_fishB.scale.set(scale.crimaxB);
    this.crimax_line.scale.set(scale.line);
    this.meter.scale.set(scale.meter);

    if (width > height) {
      this.layout = 'landscape';
    } else {
      this.layout = 'portrait';
    }
  };
}

export default FishGamble;
