import { useMutation } from '@apollo/client';
import { useCallback, useEffect } from 'react';

import { GameMode } from '../../consts';
import { setSlotConfig } from '../../gql/cache';
import { gambleGql } from '../../gql/mutation';
import { EventTypes, eventManager } from '../../slotMachine/config';

export const Collect: React.FC = () => {
  const [collect] = useMutation(gambleGql, {
    onError(error) {
      console.log(error);
    },
    async onCompleted(_data) {
      eventManager.emit(EventTypes.CHANGE_MODE, {
        mode: GameMode.SCALING,
        reelSetId: '',
        reelPositions: [0, 0, 0, 0],
        bgType: 'result',
      });
      eventManager.emit(EventTypes.SCALING_SPIN);

      //setIsCollected(true);
      //setSpinWithAutoSpin(false);
      //setIsCollectSpin(false);
      //      setUserBalance(data.selectOutcomes.balance.settled);
      //eventManager.emit(EventTypes.COLLECT_TIMEOUT, false);
      //setGambleStake(0);
      //eventManager.emit(EventTypes.DISABLE_COLLECT_BTN, true);
      //eventManager.emit(EventTypes.DISABLE_GAMBLE_BTN, true);
      //eventManager.emit(EventTypes.IS_GAMBLE_MODE, false);
      //setNextResult(data.selectOutcomes);
      //      SlotMachine.getInstance().setCollectResult(data.selectOutcomes);
      //      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, data.selectOutcomes.balance.settled);
      //setBalanceAmount(data.selectOutcomes.balance.settled.amount);
      //debugDisplay('setIsAfterSpin', setIsAfterSpin(), 'setIsAutoSpins', setIsAutoSpins());
      //if (setIsAfterSpin()) {
      //  eventManager.emit(EventTypes.AFTER_SPIN);
      //} else if (setIsAutoSpins()) {
      //  eventManager.emit(EventTypes.AUTO_SPIN_COLLECT);
      //}
    },
  });

  const gambleCancel = useCallback(() => {
    //const { id: betId, coinAmount, coinValue, outcomes } = setNextResult()!.bet;
    //const outcomeIds = outcomes.find((d) => d.predicament.caseFn === setPlayer())!.id;
    //eventManager.emit(EventTypes.COLLECT_TIMEOUT, true);
    /*collect({
      variables: {
        input: {
          betId: betId,
          coinAmount: coinAmount,
          coinValue: coinValue,
          outcomeIds: outcomeIds,
          slotId: setSlotConfig().slotId,
        },
      },
    });
    */
    collect({
      variables: {
        input: {
          betId: 'betId',
          coinAmount: 100,
          coinValue: 1,
          outcomeIds: ['collectOutcomeId'],
          slotId: setSlotConfig().slotId,
        },
      },
    });
  }, [collect]);

  const onSpinButtonClick = useCallback(() => {
    gambleCancel();
  }, [gambleCancel]);

  useEffect(() => {
    eventManager.on(EventTypes.GAMBLE_CANCEL_SELECTED, () => {
      //setPlayer(caseFn.closeGamble);
      onSpinButtonClick();
    });

    return () => {
      eventManager.removeListener(EventTypes.GAMBLE_CANCEL_SELECTED);
    };
  }, [onSpinButtonClick]);

  return null;
};
