import * as PIXI from 'pixi.js';

import { Variables } from '../../config';
import { AutoResizeTextStyle } from '../components/autoResizeText';

const font = Variables.FONT_FAMILY;

export const totalWinTitleStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: font,
  maxWidth: 1024,
  fontSize: 130,
  fontStyle: 'normal',
  fontWeight: '700',
  fill: ['#FF0000', '#FFF172'], // gradient
  fillGradientStops: [0, 1],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 6,
  dropShadow: true,
  dropShadowColor: '#000000',
  dropShadowBlur: 3,
  dropShadowAngle: Math.atan2(8, 3),
  dropShadowDistance: Math.sqrt(8 * 8 + 3 * 3),
  lineJoin: 'round',
  dropShadowAlpha: 0.4,
};

export const totalWinAmountTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: font,
  maxWidth: 1024,
  fontSize: 150,
  fontStyle: 'normal',
  fontWeight: '700',
  fill: ['#FFB22B', '#FFE693', '#FFB435'], // gradient
  fillGradientStops: [0, 0.6, 1],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 6,
  dropShadow: true,
  dropShadowColor: '#000000',
  dropShadowBlur: 3,
  dropShadowAngle: Math.atan2(8, 3),
  dropShadowDistance: Math.sqrt(8 * 8 + 3 * 3),
  lineJoin: 'round',
  dropShadowAlpha: 0.4,
};

export const freeSpinEndBannerRoundTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: font,
  maxWidth: 800,
  fontSize: 95,
  fontStyle: 'normal',
  fontWeight: '700',
  fill: ['#FF8D3A', '#FFE794', '#FF9F10'], // gradient
  fillGradientStops: [0, 0.5, 1],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 6,
  dropShadow: true,
  dropShadowColor: '#000000',
  dropShadowBlur: 3,
  dropShadowAngle: Math.atan2(8, 3),
  dropShadowDistance: Math.sqrt(8 * 8 + 3 * 3),
  lineJoin: 'round',
  dropShadowAlpha: 0.4,
};

export const freeSpinStartBannerTitleStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: font,
  maxWidth: 1033,
  fontSize: 100,
  fontStyle: 'normal',
  fontWeight: '700',
  fill: '#FFAB00',
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 6,
  dropShadow: true,
  dropShadowColor: '#000000',
  dropShadowBlur: 3,
  dropShadowAngle: Math.atan2(10, 0),
  dropShadowDistance: Math.sqrt(10 * 10 + 0 * 0),
  lineJoin: 'round',
  dropShadowAlpha: 0.7,
};

export const freeSpinStartBannerRankTitleStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: font,
  maxWidth: 800,
  fontSize: 76,
  fontStyle: 'normal',
  fontWeight: '700',
  fill: '#FF2408',
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 6,
  lineJoin: 'round',
};

export const freeSpinStartBannerRankStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: font,
  maxWidth: 800,
  fontSize: 76,
  fontStyle: 'normal',
  fontWeight: '700',
  fill: '#FFB500',
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 6,
  lineJoin: 'round',
};

export const freeSpinStartBannerDescriptionStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: font,
  maxWidth: 605,
  fontSize: 59,
  fill: '#FFFFFF',
  fontWeight: 'bold',
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 4,
  lineJoin: 'round',
};

export const freeSpinStartBannerMultiplierStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: font,
  maxWidth: 304,
  fontSize: 108,
  fill: ['#FFF0A1', '#FFA200', '#FAFF00'],
  fillGradientStops: [0, 0.5, 1],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontWeight: 'bold',
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 5,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(10, 0),
  dropShadowBlur: 4,
  dropShadowDistance: Math.sqrt(10 * 10),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};

export const snowLevelUpTitleTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: font,
  maxWidth: 400,
  fontSize: 48,
  fontStyle: 'normal',
  fontWeight: 'bold',
  fill: ['#FF0000', '#FF5C00'],
  fillGradientStops: [0, 1],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 6,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(4, 0),
  dropShadowBlur: 4,
  dropShadowDistance: Math.sqrt(4 * 4),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.5,
};

export const snowLevelUpLineWinPreTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: font,
  maxWidth: 400,
  fontSize: 40,
  fontStyle: 'normal',
  fontWeight: '700',
  fill: '#001950',
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 6,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(4, 0),
  dropShadowBlur: 4,
  dropShadowDistance: Math.sqrt(4 * 4),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.5,
};

export const snowLevelUpLineWinNextTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: font,
  maxWidth: 400,
  fontSize: 50,
  fontStyle: 'normal',
  fontWeight: '700',
  fill: '#FF0000',
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 6,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(4, 0),
  dropShadowBlur: 4,
  dropShadowDistance: Math.sqrt(4 * 4),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.5,
};

export const snowLevelUpRoundAddedTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: font,
  maxWidth: 400,
  fontSize: 32,
  fontStyle: 'normal',
  fontWeight: '700',
  fill: ['#FFD912', '#FF6B00'],
  fillGradientStops: [0, 1],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  align: 'left',
  stroke: '#FFFFFF',
  strokeThickness: 6,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(4, 0),
  dropShadowBlur: 4,
  dropShadowDistance: Math.sqrt(4 * 4),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.5,
};

export const snowLevelUpRoundAddedNumTextStyle: Partial<AutoResizeTextStyle> = {
  ...snowLevelUpRoundAddedTextStyle,
  fontSize: 45,
  align: 'right',
};

export const snowLevelUpRoundAddedOnlyTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: font,
  maxWidth: 400,
  fontSize: 58,
  fontStyle: 'normal',
  fontWeight: '700',
  fill: ['#FFD912', '#FF6B00'],
  fillGradientStops: [0, 1],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 6,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(4, 0),
  dropShadowBlur: 4,
  dropShadowDistance: Math.sqrt(4 * 4),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.5,
};
export const snowLevelUpRoundAddedNumOnlyTextStyle: Partial<AutoResizeTextStyle> = {
  ...snowLevelUpRoundAddedOnlyTextStyle,
  fontSize: 90,
};
