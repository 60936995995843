import type TaggedText from 'pixi-tagged-text';
import * as PIXI from 'pixi.js';
import { ITextStyle } from 'pixi.js';

import { Colors, Variables } from '../../config/variables';
import { AutoResizeTextStyle } from '../components/autoResizeText';
import { LayeredBitmapTextStyle } from '../components/layeredBitmapText';

// const sample = new PIXI.TextStyle({
//   align: 'center', // string;
//   breakWords: true, // ##false, // boolean;
//   dropShadow: true, // ##false, // boolean;
//   dropShadowAlpha: 0.75, // ##1, // number;
//   dropShadowAngle: 0.785, // ##Math.PI / 6, // number;
//   dropShadowBlur: 10, // ##0, // number;
//   dropShadowColor: 0x000000, // ##'black', // string | number;
//   dropShadowDistance: 4, // number;
//   fill: [0xffc000, 0xff0000], // ##'black', // string | string[] | number | number[] | CanvasGradient | CanvasPattern;
//   fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL, // number;
//   // fillGradientStops: [0,1], //number[];
//   fontFamily: Variables.FONT_FAMILY, // string | string[];
//   fontSize: 80, // number | string;
//   fontStyle: 'normal', // string;
//   fontVariant: 'normal', // string;
//   fontWeight: 'bold', // ## 'normal', // string;
//   leading: 0, // number;
//   letterSpacing: 0, // number;
//   // lineHeight: 0, //number;
//   lineJoin: 'round', // ##'miter', // string;
//   miterLimit: 10, // number;
//   padding: 4, // ##0, // number;
//   stroke: '#ffffff', // ##'black', // string | number;
//   strokeThickness: 6, // ##0, // number;
//   trim: false, // boolean;
//   textBaseline: 'alphabetic', // string;
//   whiteSpace: 'pre', // string;
//   wordWrap: false, // boolean;
//   wordWrapWidth: 1330, // number;
// });

export const miniPayTableNumberStyle: Partial<ITextStyle> = {
  fontSize: 31,
  fontFamily: Variables.FONT_FAMILY,
  whiteSpace: 'normal',
  fill: '0xffffff',
  trim: true,
  fontWeight: '700',
  align: 'left',
  lineHeight: 50,
  stroke: '#000000',
  strokeThickness: 6,
  lineJoin: 'round',
};

export const miniPayTableTextStyle: Partial<ITextStyle> = {
  fontSize: 31,
  fontFamily: Variables.FONT_FAMILY,
  whiteSpace: 'normal',
  fill: Colors.GAME_COLOR,
  trim: true,
  fontWeight: '700',
  align: 'left',
  lineHeight: 50,
  stroke: '#000000',
  strokeThickness: 6,
  lineJoin: 'round',
};

export const bottomContainerTextStyle: TextStyleSet = {
  default: {
    fontFamily: Variables.FONT_FAMILY,
    fontWeight: '700',
    fill: '#ffffff',
    fontSize: 26,
    lineHeight: 26,
    trim: true,
  },
  color: {
    fill: Colors.GAME_COLOR,
  },
};

//auto resize not work.
export const baseCountUpStyle: Partial<AutoResizeTextStyle> = {
  align: 'center',
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 150,
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: '700',
  leading: 0,
  lineJoin: 'round',
  padding: 4,
  trim: false,
  fill: ['#FF0000', '#FFF500'],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fillGradientStops: [0, 1],
  stroke: '#ffffff',
  strokeThickness: 10,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 0.8,
  dropShadowAngle: Math.atan2(15, 0),
  dropShadowBlur: 4,
  dropShadowDistance: Math.sqrt(15 * 15),
  maxWidth: 1203,
};

export const countUpTextStyle: LayeredBitmapTextStyle = {
  name: 'countUp',
  layerList: [
    {
      style: {
        ...baseCountUpStyle,
      },
    },
    /*
    {
      offset: [0, 0],
      style: {
        ...baseCountUpStyle,

      },
    },
    */
  ],
};

export const baseSoldOutCountUpStyle: Partial<AutoResizeTextStyle> = {
  fill: 0xdd0000,
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: '700',
  lineJoin: 'round',
  padding: 1,
  align: 'center',
  fontSize: 164,
  letterSpacing: 0,
};
export const countUpSoldOutTextStyle: LayeredBitmapTextStyle = {
  name: 'countUpSoldOut',
  layerList: [
    {
      style: {
        ...baseSoldOutCountUpStyle,
        stroke: '#ffffff',
        strokeThickness: 5,
      },
    },
    {
      offset: [7, 1],
      style: {
        ...baseSoldOutCountUpStyle,
        dropShadow: true,
        dropShadowColor: 0x000000,
        dropShadowAlpha: 1.0,
        dropShadowAngle: Math.PI / 4,
        dropShadowBlur: 3,
        dropShadowDistance: 20,
      },
    },
  ],
};

type TextStyleSet = (typeof TaggedText)['defaultStyles'];

export const messageWindowAutoResizeTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  fill: 0xffffff,
  fontSize: 64,
  lineJoin: 'round',
  fontWeight: '500',
  dropShadow: false,
  letterSpacing: 0,
  align: 'justify',
  fontStyle: 'normal',
  wordWrap: false,
  maxWidth: 1077,
  maxHeight: 72,
};

export const autoPlayCounterTextStyle: Partial<ITextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  stroke: 'black',
  strokeThickness: 4,
  fontSize: 25,
  whiteSpace: 'normal',
  align: 'center',
  fill: Colors.GAME_COLOR,
};

export const clockTextStyle: Partial<ITextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  fontSize: 20,
  whiteSpace: 'normal',
  align: 'center',
  fill: 0xffffff,
  trim: true,
};

export const gameNameTextStyle: Partial<ITextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  fontSize: 20,
  whiteSpace: 'normal',
  align: 'center',
  fill: 0xffffff,
  trim: true,
};

export const soundToastStyles: TextStyleSet = {
  default: {
    fontFamily: Variables.FONT_FAMILY,
    fontSize: 40,
    fill: '#ffffff',
    stroke: 'black',
    strokeThickness: 5,
  },
  color: {
    fill: Colors.GAME_COLOR,
  },
};

export const winLabelTextStyle: Partial<AutoResizeTextStyle> = {
  align: 'center',
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 150,
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: '700',
  leading: 0,
  lineJoin: 'round',
  padding: 4,
  trim: false,
  fill: ['#FF3D00', '#FF7B01', '#FFB802'],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fillGradientStops: [0, 0.5, 1.0],
  stroke: '#ffffff',
  strokeThickness: 10,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 0.8,
  dropShadowAngle: Math.atan2(14, 0),
  dropShadowBlur: 2,
  dropShadowDistance: Math.sqrt(14 * 14),
  maxWidth: 1203,
};
