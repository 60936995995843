import { TAudioSprite } from '@phoenix7dev/audio-api/dist/d';

export const ISongs = {
  SFX_UI_AutoSpin: 'SFX_UI_AutoSpin',
  SFX_UI_BetChange: 'SFX_UI_BetChange',
  SFX_UI_Close: 'SFX_UI_Close',
  SFX_UI_General: 'SFX_UI_General',
  SFX_UI_Hover: 'SFX_UI_Hover',
  SFX_UI_MaxBet: 'SFX_UI_MaxBet',
  SFX_UI_MenuOpen: 'SFX_UI_MenuOpen',
  XT004S_phoenix_sign: 'XT004S_phoenix_sign',
  XT005S_SC1_stop: 'XT005S_SC1_stop',
  XT005S_SC4_stop: 'XT005S_SC4_stop',
  XT005S_SC_longspin_long: 'XT005S_SC_longspin_long',
  XT005S_SC_longspin_middle: 'XT005S_SC_longspin_middle',
  XT005S_SC_longspin_short: 'XT005S_SC_longspin_short',
  XT005S_anticipation_high: 'XT005S_anticipation_high',
  XT005S_anticipation_low: 'XT005S_anticipation_low',
  XT005S_bg_base_loop: 'XT005S_bg_base_loop',
  XT005S_bg_challenge1_loop: 'XT005S_bg_challenge1_loop',
  XT005S_bg_challenge2_loop: 'XT005S_bg_challenge2_loop',
  XT005S_bg_challenge3_loop: 'XT005S_bg_challenge3_loop',
  XT005S_bg_challenge_roulette_loop: 'XT005S_bg_challenge_roulette_loop',
  XT005S_bg_gotcha_loop: 'XT005S_bg_gotcha_loop',
  XT005S_bg_result_loop: 'XT005S_bg_result_loop',
  XT005S_bg_scaling: 'XT005S_bg_scaling',
  XT005S_bigwin_loop: 'XT005S_bigwin_loop',
  XT005S_challenge_roulette: 'XT005S_challenge_roulette',
  XT005S_challenge_roulette_stop: 'XT005S_challenge_roulette_stop',
  XT005S_challenge_round: 'XT005S_challenge_round',
  XT005S_feature_trigger: 'XT005S_feature_trigger',
  XT005S_push_buy_feature: 'XT005S_push_buy_feature',
  XT005S_spin_loop: 'XT005S_spin_loop',
  XT005S_spin_start: 'XT005S_spin_start',
  XT005S_spin_stop1: 'XT005S_spin_stop1',
  XT005S_spin_stop2: 'XT005S_spin_stop2',
  XT005S_spin_stop3: 'XT005S_spin_stop3',
  XT005S_spin_stop4: 'XT005S_spin_stop4',
  XT005S_spin_stop5: 'XT005S_spin_stop5',
  XT005S_wheel_miss: 'XT005S_wheel_miss',
  XT005S_wheel_nice: 'XT005S_wheel_nice',
  XT005S_wheel_rotate: 'XT005S_wheel_rotate',
  XT005S_wheel_spin_push: 'XT005S_wheel_spin_push',
  XT005S_win_big: 'XT005S_win_big',
  XT005S_win_end: 'XT005S_win_end',
  XT005S_win_epic: 'XT005S_win_epic',
  XT005S_win_great: 'XT005S_win_great',
  XT005S_win_loop: 'XT005S_win_loop',
  XT005S_win_mega: 'XT005S_win_mega',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ISongs = (typeof ISongs)[keyof typeof ISongs];

export const audioSprite: TAudioSprite = {
  SFX_UI_AutoSpin: [0, 320.4761904761905],
  SFX_UI_BetChange: [2000, 32.086167800453595],
  SFX_UI_Close: [4000, 200.6802721088432],
  SFX_UI_General: [6000, 88.34467120181432],
  SFX_UI_Hover: [8000, 151.76870748299365],
  SFX_UI_MaxBet: [10000, 122.92517006802761],
  SFX_UI_MenuOpen: [12000, 277.14285714285757],
  XT004S_phoenix_sign: [14000, 4033.333333333331],
  XT005S_SC1_stop: [20000, 500],
  XT005S_SC4_stop: [22000, 1500],
  XT005S_SC_longspin_long: [25000, 3301.020408163264],
  XT005S_SC_longspin_middle: [30000, 2766.6893424036302],
  XT005S_SC_longspin_short: [34000, 2266.6666666666656],
  XT005S_anticipation_high: [38000, 3633.333333333333],
  XT005S_anticipation_low: [43000, 4000],
  XT005S_bg_base_loop: [48000, 79285.71428571429, true],
  XT005S_bg_challenge1_loop: [129000, 10666.689342403628, true],
  XT005S_bg_challenge2_loop: [141000, 10666.689342403628, true],
  XT005S_bg_challenge3_loop: [153000, 10666.689342403628, true],
  XT005S_bg_challenge_roulette_loop: [165000, 11162.811791383207, true],
  XT005S_bg_gotcha_loop: [178000, 27187.551020408166, true],
  XT005S_bg_result_loop: [207000, 7500, true],
  XT005S_bg_scaling: [216000, 7500],
  XT005S_bigwin_loop: [225000, 7500, true],
  XT005S_challenge_roulette: [234000, 7000],
  XT005S_challenge_roulette_stop: [242000, 3000],
  XT005S_challenge_round: [246000, 2020.4308390022732],
  XT005S_feature_trigger: [250000, 5000],
  XT005S_push_buy_feature: [256000, 366.66666666667425],
  XT005S_spin_loop: [258000, 2000.0226757369433, true],
  XT005S_spin_start: [262000, 1000],
  XT005S_spin_stop1: [264000, 500.4308390022629],
  XT005S_spin_stop2: [266000, 500.4308390022629],
  XT005S_spin_stop3: [268000, 500.4308390022629],
  XT005S_spin_stop4: [270000, 500.4308390022629],
  XT005S_spin_stop5: [272000, 500.4308390022629],
  XT005S_wheel_miss: [274000, 1500],
  XT005S_wheel_nice: [277000, 2000],
  XT005S_wheel_rotate: [280000, 5000],
  XT005S_wheel_spin_push: [286000, 2000],
  XT005S_win_big: [289000, 1800.068027210898],
  XT005S_win_end: [292000, 1000],
  XT005S_win_epic: [294000, 3000.0453514739434],
  XT005S_win_great: [299000, 1800.068027210898],
  XT005S_win_loop: [302000, 1000, true],
  XT005S_win_mega: [304000, 1800.068027210898],
};
