import * as PIXI from 'pixi.js';

import ViewContainer from './container';

class ButtonContainer extends ViewContainer {
  protected clickCallback: (() => void) | undefined;
  constructor(
    x: number,
    y: number,
    w: number,
    h: number,
    clickCallback?: () => void,
    overCallback?: () => void,
    outCallback?: () => void,
    downCallback?: () => void,
    upCallback?: () => void,
  ) {
    super();

    this.clickCallback = clickCallback;
    this.interactive = true;
    this.buttonMode = true;
    this.width = w;
    this.height = h;
    this.hitArea = new PIXI.Rectangle(x, y, w, h);
    this.on('click', (ev: PIXI.InteractionEvent) => {
      if (ev.data.isPrimary) clickCallback?.();
    });
    this.on('touchstart', (ev: PIXI.InteractionEvent) => {
      if (ev.data.isPrimary) clickCallback?.();
    });

    this.addListener('mouseover', () => {
      overCallback?.();
    });
    this.addListener('mouseout', () => {
      outCallback?.();
    });
    this.addListener('mousedown', () => {
      downCallback?.();
    });
    this.addListener('mouseup', () => {
      upCallback?.();
    });
  }
}

export default ButtonContainer;
