import { GameMode } from '../../../consts';
import ViewContainer from '../../components/container';
import { layerReelBack, layerReelBackFront, layerReelFrame } from '../../components/layers/layers';
import { TickerSpine } from '../../components/spine';
import {
  EventTypes,
  SLOTS_REELFRAME_HEIGHT,
  SLOTS_REELFRAME_WIDTH,
  SLOTS_REELFRAME_X,
  SLOTS_REELFRAME_Y,
  eventManager,
} from '../../config';

class ReelsFrame extends ViewContainer {
  private frame_b: TickerSpine<'reelframe'>;
  private frame_f: TickerSpine<'reelframe'>;

  constructor() {
    super();
    this.frame_b = new TickerSpine('reelframe');
    this.frame_b.position.set(
      SLOTS_REELFRAME_X + SLOTS_REELFRAME_WIDTH / 2,
      SLOTS_REELFRAME_Y + SLOTS_REELFRAME_HEIGHT / 2,
    );
    this.frame_b.state.setAnimation(0, 'back', true);
    this.frame_b.parentLayer = layerReelBack;

    this.frame_f = new TickerSpine('reelframe');
    this.frame_f.position.set(
      SLOTS_REELFRAME_X + SLOTS_REELFRAME_WIDTH / 2,
      SLOTS_REELFRAME_Y + SLOTS_REELFRAME_HEIGHT / 2,
    );
    this.frame_f.state.setAnimation(0, 'base', true);
    this.frame_f.parentLayer = layerReelFrame;

    this.addChild(this.frame_b, this.frame_f);

    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.START_FS_WIN_ANIMATION, this.setLayer.bind(this));
  }
  private onChangeMode(_settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
  }) {
    this.frame_f.parentLayer = layerReelFrame;
  }

  private setLayer() {
    this.frame_f.parentLayer = layerReelBackFront;
  }
}

export default ReelsFrame;
