import { ISongs } from '../../config';

import {
  BIG_WIN_AMOUNT_LIMIT,
  BIG_WIN_COUNT_UP_MULTIPLIER,
  BIG_WIN_TITLE_SCALE,
  EPIC_WIN_COUNT_UP_MULTIPLIER,
  EPIC_WIN_TITLE_SCALE,
  GREAT_WIN_AMOUNT_LIMIT,
  GREAT_WIN_COUNT_UP_MULTIPLIER,
  GREAT_WIN_TITLE_SCALE,
  MEGA_WIN_AMOUNT_LIMIT,
  MEGA_WIN_COUNT_UP_MULTIPLIER,
  MEGA_WIN_TITLE_SCALE,
  WinStages,
} from './../config/index';

export type BigWinStage = WinStages.BigWin | WinStages.MegaWin | WinStages.GreatWin | WinStages.EpicWin;

export type WinCountUpAnimationSettingType = {
  sound: ISongs;
  ownStage: WinStages;
  amountLimit: number;
  countUpMultiplier: number;
  beginValue: number;
  beginScale: number;
  targetScale: number;
};

export const WinCountUpAnimationSettings: Record<BigWinStage, WinCountUpAnimationSettingType> = {
  [WinStages.BigWin]: {
    sound: ISongs.XT005S_win_big,
    ownStage: WinStages.BigWin,
    amountLimit: BIG_WIN_AMOUNT_LIMIT,
    countUpMultiplier: BIG_WIN_COUNT_UP_MULTIPLIER,
    beginValue: 0,
    beginScale: 1,
    targetScale: BIG_WIN_TITLE_SCALE,
  },
  [WinStages.MegaWin]: {
    sound: ISongs.XT005S_win_mega,
    ownStage: WinStages.MegaWin,
    amountLimit: MEGA_WIN_AMOUNT_LIMIT,
    countUpMultiplier: MEGA_WIN_COUNT_UP_MULTIPLIER,
    beginValue: BIG_WIN_AMOUNT_LIMIT,
    beginScale: BIG_WIN_TITLE_SCALE,
    targetScale: MEGA_WIN_TITLE_SCALE,
  },
  [WinStages.GreatWin]: {
    sound: ISongs.XT005S_win_great,
    ownStage: WinStages.GreatWin,
    amountLimit: GREAT_WIN_AMOUNT_LIMIT,
    countUpMultiplier: GREAT_WIN_COUNT_UP_MULTIPLIER,
    beginValue: MEGA_WIN_AMOUNT_LIMIT,
    beginScale: MEGA_WIN_TITLE_SCALE,
    targetScale: GREAT_WIN_TITLE_SCALE,
  },
  [WinStages.EpicWin]: {
    sound: ISongs.XT005S_win_epic,
    ownStage: WinStages.EpicWin,
    amountLimit: Number.POSITIVE_INFINITY,
    countUpMultiplier: EPIC_WIN_COUNT_UP_MULTIPLIER,
    beginValue: GREAT_WIN_AMOUNT_LIMIT,
    beginScale: GREAT_WIN_TITLE_SCALE,
    targetScale: EPIC_WIN_TITLE_SCALE,
  },
};
