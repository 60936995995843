import i18n from 'i18next';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs } from '../../config';
import { setCurrency, setIsBuyFeaturePurchased, setIsSuperBuyFeature } from '../../gql/cache';
import { showCurrency } from '../../utils';
import AutoResizeText from '../components/autoResizeText';
import { SpriteButton } from '../components/button';
import { layerBuyFeaturePopUp } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import { EventTypes, eventManager } from '../config';

import { FEATURE_POPUP_POSITIONS } from './config';
import {
  buyFeatureNormalCostTextStyle,
  buyFeatureNormalTitleTextStyle,
  buyFeatureSpecialDescriptionTextStyle,
  buyFeatureSpecialTitleTextStyle,
  buyFeatureTitleStyle,
  confirmTextStyle,
  confirmTotalCostTextStyle,
  totalCostTextAmountStyle,
} from './textStyles';

export class BuyFeaturePopupConfirm extends PIXI.Container {
  private popupBg: TickerSpine<'buy'>;
  private titleText: AutoResizeText;

  private confirmText: AutoResizeText;

  private normal: TickerSpine<'buy'>;
  private normalTitleText: AutoResizeText;
  private normalCostText: AutoResizeText;
  private normalContainer: PIXI.Container;

  private special: TickerSpine<'buy'>;
  private specialTitleText: AutoResizeText;
  private specialCostText: AutoResizeText;
  private specialDescText: AutoResizeText;
  private specialContainer: PIXI.Container;

  private totalCostText: AutoResizeText;
  private totalCostValue: AutoResizeText;

  private okBtn: SpriteButton;

  private cancelBtn: SpriteButton;

  private baseContainer: PIXI.Container;
  private confirmContainer: PIXI.Container;

  private bonusIdx: number;

  constructor() {
    super();
    this.visible = false;
    this.bonusIdx = 0;

    this.popupBg = this.initPopupBg();
    this.titleText = this.initTitle();

    this.popupBg = this.initPopupBg();
    this.titleText = this.initTitle();
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();
    this.baseContainer = new PIXI.Container();
    this.baseContainer.addChild(this.popupBg, this.titleText, this.cancelBtn, this.okBtn);
    this.baseContainer.position.copyFrom(FEATURE_POPUP_POSITIONS.BaseContainer.Portrait);

    this.normal = this.initNormalBtn();
    this.normalTitleText = this.initNormalTitleText();
    this.normalCostText = this.initNormalCostText();
    this.normalContainer = new PIXI.Container();
    this.normalContainer.addChild(this.normal, this.normalTitleText, this.normalCostText);
    this.normalContainer.position.copyFrom(FEATURE_POPUP_POSITIONS.Confirm.iconContainer);

    this.special = this.initSpecialBtn();
    this.specialTitleText = this.initSpecialTitleText();
    this.specialCostText = this.initSpecialCostText();
    this.specialDescText = this.initSpecialDescriptionText();
    this.specialContainer = new PIXI.Container();
    this.specialContainer.addChild(this.special, this.specialTitleText, this.specialCostText, this.specialDescText);
    this.specialContainer.position.copyFrom(FEATURE_POPUP_POSITIONS.Confirm.iconContainer);

    this.confirmText = this.initConfirmText();
    this.totalCostText = this.initTotalCostText();
    this.totalCostValue = this.initTotalCostValue();

    this.confirmContainer = new PIXI.Container();
    this.confirmContainer.addChild(
      this.confirmText,
      this.normalContainer,
      this.specialContainer,
      this.totalCostText,
      this.totalCostValue,
    );
    this.confirmContainer.position.copyFrom(FEATURE_POPUP_POSITIONS.MenuContainer.Portrait);
    this.addChild(this.baseContainer, this.confirmContainer);

    this.interactive = true;

    this.init();
  }

  private init(): void {
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => this.handleClose());
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, () => this.handleClose());
    eventManager.on(EventTypes.BUY_FEATURE_CONFIRMED, () => this.handleClose());
    eventManager.on(
      EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP,
      (totalCost: string, coinAmount: number, isSuper: boolean) => {
        this.okBtn.disable = false;
        this.cancelBtn.disable = false;
        this.handleOpen(totalCost, coinAmount, isSuper);
      },
    );
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));

    this.parentLayer = layerBuyFeaturePopUp;
  }
  private initPopupBg(): TickerSpine {
    const popupBg = new TickerSpine('buy');
    popupBg.update(0);
    popupBg.state.setAnimation(0, 'base', true);
    return popupBg;
  }

  private initTitle(): AutoResizeText {
    const title = new AutoResizeText(i18n.t('buyFeature.title'), buyFeatureTitleStyle);
    title.position.copyFrom(FEATURE_POPUP_POSITIONS.BaseContainer.Title);
    title.anchor.set(0.5, 0.5);

    return title;
  }

  private initNormalBtn(): TickerSpine {
    const spine = new TickerSpine('buy');
    spine.update(0);
    spine.state.setAnimation(0, 'btn_nml', true);
    spine.position.copyFrom(FEATURE_POPUP_POSITIONS.MenuContainer.Normal.Base);
    return spine;
  }
  private initNormalTitleText(): AutoResizeText {
    const text = new AutoResizeText(i18n.t('buyFeature.normalBait'), buyFeatureNormalTitleTextStyle);
    text.position.copyFrom(FEATURE_POPUP_POSITIONS.MenuContainer.Normal.Title);
    text.anchor.set(0.5, 0.5);
    return text;
  }
  private initNormalCostText(): AutoResizeText {
    const text = new AutoResizeText(
      `${formatNumber({
        currency: setCurrency(),
        value: 0,
        showCurrency: showCurrency(setCurrency()),
      })}`,
      buyFeatureNormalCostTextStyle,
    );
    text.position.copyFrom(FEATURE_POPUP_POSITIONS.MenuContainer.Normal.Cost);
    text.anchor.set(0.5, 0.5);
    return text;
  }

  private initSpecialBtn(): TickerSpine {
    const spine = new TickerSpine('buy');
    spine.update(0);
    spine.state.setAnimation(0, 'btn_sp', true);
    spine.position.copyFrom(FEATURE_POPUP_POSITIONS.MenuContainer.Special.Base);
    return spine;
  }

  private initSpecialTitleText(): AutoResizeText {
    const betText = new AutoResizeText(i18n.t('buyFeature.specialBait'), buyFeatureSpecialTitleTextStyle);
    betText.position.copyFrom(FEATURE_POPUP_POSITIONS.MenuContainer.Special.Title);
    betText.anchor.set(0.5, 0.5);
    return betText;
  }

  private initSpecialCostText(): AutoResizeText {
    const betValue = new AutoResizeText(
      `${formatNumber({
        currency: setCurrency(),
        value: 0,
        showCurrency: showCurrency(setCurrency()),
      })}`,
      buyFeatureNormalCostTextStyle,
    );
    betValue.position.copyFrom(FEATURE_POPUP_POSITIONS.MenuContainer.Special.Cost);
    betValue.anchor.set(0.5, 0.5);
    return betValue;
  }

  private initSpecialDescriptionText(): AutoResizeText {
    const betText = new AutoResizeText(i18n.t('buyFeature.specialDesc'), buyFeatureSpecialDescriptionTextStyle);
    betText.position.copyFrom(FEATURE_POPUP_POSITIONS.MenuContainer.Special.Description);
    betText.anchor.set(0.5, 0.5);
    return betText;
  }

  private initConfirmText(): AutoResizeText {
    const totalCostText = new AutoResizeText(i18n.t('buyFeature.confirm.title'), confirmTextStyle);
    totalCostText.position.copyFrom(FEATURE_POPUP_POSITIONS.Confirm.description);
    totalCostText.anchor.set(0.5, 0.5);

    return totalCostText;
  }

  private initTotalCostText(): AutoResizeText {
    const totalCostText = new AutoResizeText(i18n.t('buyFeature.totalCost'), confirmTotalCostTextStyle);
    totalCostText.position.copyFrom(FEATURE_POPUP_POSITIONS.Confirm.totalCostText);
    totalCostText.anchor.set(0.5, 0.5);

    return totalCostText;
  }

  private initTotalCostValue(): AutoResizeText {
    const totalCostValue = new AutoResizeText('0', totalCostTextAmountStyle);
    totalCostValue.anchor.set(0.5, 0.5);
    totalCostValue.position.copyFrom(FEATURE_POPUP_POSITIONS.Confirm.totalCostValue);

    return totalCostValue;
  }

  private initCancelBtn(): SpriteButton {
    const cancelBtn = new SpriteButton(
      {
        default: 'gamble_btn_back',
        hover: 'gamble_btn_back_over',
        press: 'gamble_btn_back_push',
        disable: 'gamble_btn_back_disable',
      },
      () => {
        this.okBtn.disable = false;
        this.cancelBtn.disable = false;
        this.handleClose();
        eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP_BG);
        AudioApi.play({ type: ISongs.SFX_UI_Close });
      },
    );
    cancelBtn.position.copyFrom(FEATURE_POPUP_POSITIONS.BaseContainer.CancelBtn);
    cancelBtn.anchor.set(0.5);

    return cancelBtn;
  }

  private initOkBtn(): SpriteButton {
    const okBtn = new SpriteButton(
      {
        default: 'gamble_btn_ok',
        hover: 'gamble_btn_ok_over',
        press: 'gamble_btn_ok_push',
        disable: 'gamble_btn_ok_disable',
      },
      () => {
        this.okBtn.disable = false;
        this.cancelBtn.disable = false;
        this.okBtn.interactive = false;
        this.cancelBtn.interactive = false;
        AudioApi.play({ type: ISongs.SFX_UI_General });
        eventManager.emit(EventTypes.HANDLE_BUY_BONUS, this.bonusIdx);
        setIsBuyFeaturePurchased(true);
        setIsSuperBuyFeature(this.bonusIdx === 1);
      },
    );
    okBtn.position.copyFrom(FEATURE_POPUP_POSITIONS.BaseContainer.OkBtn);
    okBtn.anchor.set(0.5);

    return okBtn;
  }

  public handleOpen(totalCost: string, _coinAmount: number, isSuper: boolean): void {
    this.visible = true;
    this.bonusIdx = isSuper ? 1 : 0;
    this.okBtn.interactive = true;
    this.cancelBtn.interactive = true;
    this.totalCostValue.text = totalCost;
    this.normalCostText.text = totalCost;
    this.specialCostText.text = totalCost;
    if (isSuper) {
      this.normalContainer.visible = false;
      this.specialContainer.visible = true;
    } else {
      this.normalContainer.visible = true;
      this.specialContainer.visible = false;
    }
  }

  public handleClose(): void {
    this.visible = false;
    this.okBtn._trackedPointers = {};
  }

  private applicationResize = (width: number, height: number): void => {
    this.handleResize(width, height);
  };

  private handleResize(width: number, height: number): void {
    if (height > width) {
      this.x = 0;
      this.y = 0;
      this.scale.set(FEATURE_POPUP_POSITIONS.scale.x);
      this.scale.set(1);
    } else {
      this.x = FEATURE_POPUP_POSITIONS.landscape.x;
      this.y = FEATURE_POPUP_POSITIONS.landscape.y;
      this.scale.set(FEATURE_POPUP_POSITIONS.scale.x);
    }
  }
}
