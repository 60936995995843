export const BANNER_POSITION_X = 0;
export const BANNER_POSITION_Y = -50;

// FS BANNER
export const MESSAGE_FS_BANNER_TITLE_Y = -150;
export const MESSAGE_FS_BANNER_YOU_WON_Y = -90;
export const MESSAGE_FS_BANNER_FEAT_NAME_Y = 0;
export const MESSAGE_FS_BANNER_DESCRIPTION_Y = 90;
export const MESSAGE_FS_BANNER_PRESS_Y = 214;

// WIN BANNER
export const MESSAGE_FS_END_BANNER_TITLE_Y = -148;
export const MESSAGE_FS_END_BANNER_WIN_AMOUNT_Y = 0;
export const MESSAGE_FS_END_BANNER_RESPIN_Y = 152;

export const FREESPIN_START_BANNER_TITLE_POS = { x: -7, y: -535 };
export const FREESPIN_START_BANNER_TITLE_PORTRAIT_POS = { x: -7, y: -605 };
export const FREESPIN_START_BANNER_RANK_TITLE_POS = { x: 674 + 232 / 2 - 1210 / 2 + 137, y: 59 - 1056 / 2 + 112 / 2 };
export const FREESPIN_START_BANNER_RANK_POS = { x: 648 + 284 / 2 + 137 - 1210 / 2, y: 82 + 112 / 2 + 59 - 1056 / 2 };
export const FREESPIN_START_BANNER_DESCRIPTION_POS = {
  x: 0 + 605 / 2 + 137 - 1210 / 2,
  y: 697 + 80 / 2 + 59 - 1056 / 2,
};
