import { FishKind } from '../../config';
import { SpineInterface } from '../../config/spine.generated';
import { GameMode } from '../../consts';
import { setIsContinueAutoSpinsAfterFeature, setIsStopOnFeatureWin } from '../../gql/cache';
import Tween from '../animations/tween';
import { BgmControl } from '../bgmControl/bgmControl';
import ViewContainer from '../components/container';
import { layerFishingPrize } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import { EventTypes, eventManager } from '../config';

import { FISHING_PRIZE_PORTRAIT_POS, FISHING_PRIZE_POS } from './config';

class FishPrize extends ViewContainer {
  private bg: TickerSpine<'fc_suc'>;
  private back: TickerSpine<'fc_suc'>;
  private prize: TickerSpine<'fc_suc'>;
  private front: TickerSpine<'fc_suc'>;

  private layout: 'portrait' | 'landscape';

  constructor() {
    super();

    this.layout = 'landscape';

    this.bg = new TickerSpine('fc_suc');
    this.bg.state.setAnimation(0, 'bg', true);

    this.back = new TickerSpine('fc_suc');
    this.back.state.setAnimation(0, 'y_back', true);

    this.prize = new TickerSpine('fc_suc');
    this.prize.state.setAnimation(0, 'prize_pt01', false);

    this.front = new TickerSpine('fc_suc');
    this.front.state.setAnimation(0, 'y_front', true);

    this.addChild(this.bg, this.back, this.prize, this.front);

    eventManager.on(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));
    eventManager.on(EventTypes.FISHING_CHALLENGE_GOTCHA_START, this.start.bind(this));
    eventManager.addListener(EventTypes.FISHING_SCALING_START, this.onScalingStart.bind(this));

    this.parentLayer = layerFishingPrize;

    this.visible = false;
  }

  public start(fishRank: FishKind) {
    const rankToAnim: Record<FishKind, string> = {
      Rank1: 'pt01',
      Rank2: 'pt02',
      Rank3: 'pt03',
      Rank4: 'pt04',
      Rank5: 'pt05',
      Rank6: 'pt06',
      Rank7: 'pt07',
    };
    const ptn = rankToAnim[fishRank];
    const layout = this.layout === 'portrait' ? 't' : 'y';
    const back = `${layout}_back` as SpineInterface['fc_suc']['animations'];
    const front = `${layout}_front` as SpineInterface['fc_suc']['animations'];
    const prize = `prize_${ptn}` as SpineInterface['fc_suc']['animations'];

    this.back.state.setAnimation(0, back, false);
    this.prize.state.setAnimation(0, prize, false);
    this.front.state.setAnimation(0, front, false);

    this.visible = true;

    const prizeAnimation = Tween.createDelayAnimation(3000);
    prizeAnimation.addOnComplete(() => {
      eventManager.emit(EventTypes.FISHING_CHALLENGE_GOTCHA_END);

      if (fishRank === 'Rank1' || (setIsContinueAutoSpinsAfterFeature() && !setIsStopOnFeatureWin())) {
        eventManager.emit(EventTypes.GAMBLE_CANCEL_SELECTED, fishRank);
      } else {
        this.visible = false;

        eventManager.emit(EventTypes.CHANGE_MODE, {
          mode: GameMode.FISH_GAMBLE_SELECT,
          reelSetId: '',
          reelPositions: [0, 0, 0, 0],
          bgType: 'gamble',
        });
        eventManager.emit(EventTypes.FISHING_GAMBLE_SELECT_START, fishRank);
      }
    });
    prizeAnimation.start();

    BgmControl.playBgm('gamble', true, true);
  }

  private onChangeMode(_settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
    fishRank?: FishKind;
  }) {
    if (_settings.mode === GameMode.BASE_GAME) {
      this.visible = false;
    }
  }

  private onScalingStart() {
    this.visible = false;
  }

  private applicationResize = (width: number, height: number): void => {
    if (width > height) {
      this.x = FISHING_PRIZE_POS.x;
      this.y = FISHING_PRIZE_POS.y;

      if (this.layout === 'portrait') {
        this.back.state.setAnimation(0, 'y_back', true);
        this.front.state.setAnimation(0, 'y_front', true);
      }
      this.layout = 'landscape';
    } else {
      this.x = FISHING_PRIZE_PORTRAIT_POS.x;
      this.y = FISHING_PRIZE_PORTRAIT_POS.y;

      if (this.layout === 'landscape') {
        this.back.state.setAnimation(0, 't_back', true);
        this.front.state.setAnimation(0, 't_front', true);
      }
      this.layout = 'portrait';
    }
  };
}

export default FishPrize;
