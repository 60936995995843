import ViewContainer from '../../components/container';
import { layerReelBack } from '../../components/layers/layers';
import {
  EventTypes,
  SLOTS_REELFRAME_HEIGHT,
  SLOTS_REELFRAME_WIDTH,
  SLOTS_REELFRAME_X,
  SLOTS_REELFRAME_Y,
  eventManager,
} from '../../config';

import { TickerSpine } from './../../components/spine/index';

class ReelsBackgroundContainer extends ViewContainer {
  private bgSpine: TickerSpine<'reelframe'>;

  constructor() {
    super();

    this.bgSpine = new TickerSpine('reelframe');
    this.bgSpine.position.set(
      SLOTS_REELFRAME_X + SLOTS_REELFRAME_WIDTH / 2,
      SLOTS_REELFRAME_Y + SLOTS_REELFRAME_HEIGHT / 2,
    );
    this.addChild(this.bgSpine);

    this.bgSpine.state.setAnimation(0, 'back', true);

    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    //    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.onStartSpin.bind(this));
    eventManager.addListener(EventTypes.REEL_STOPPED, this.onReelStopped.bind(this));
    eventManager.addListener(EventTypes.REELS_STOP_ANIMATION_COMPLETE, this.onAnticipationAnimationEnd.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_END, this.onAnticipationAnimationEnd.bind(this));

    eventManager.addListener(EventTypes.FORCE_STOP_REELS, this.forceStopReels.bind(this));

    //    eventManager.addListener(EventTypes.SETUP_REEL_POSITIONS, this.setupCutinAnimation.bind(this));

    this.parentLayer = layerReelBack;
  }

  private onModeChange(): void {}

  private onStartSpin(): void {}

  private onReelStopped() {}

  private forceStopReels() {}

  private onAnticipationAnimationEnd(): void {}
}

export default ReelsBackgroundContainer;
