import * as PIXI from 'pixi.js';

import { setIsSlotBusy } from '../../gql/cache';
import { getCombosDataFromIcon } from '../../utils';
import { layerMessageWindow } from '../components/layers/layers';
import {
  EventTypes,
  REELS_AMOUNT,
  REEL_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  SLOT_RESOURCE_HEIGHT,
  SLOT_WIDTH,
  eventManager,
} from '../config';
import { Icon } from '../d';
import { Slot } from '../slot/slot';

import MiniPayTable from './miniPayTable';

//const CONTAINER_LEFT_ADJUST = 0;
const HIT_AREA_ADJUST = 50;
class MiniPayTableContainer extends PIXI.Container {
  private getSlotById: (id: number) => Slot | null;

  private payTableContainers: PIXI.Container<MiniPayTable>[];

  constructor(icons: Icon[], getSlotByIdFn: (id: number) => Slot | null) {
    super();
    this.getSlotById = getSlotByIdFn;
    this.visible = true;
    eventManager.addListener(EventTypes.DISABLE_PAY_TABLE, (isVisible: boolean) => (this.visible = isVisible));
    eventManager.addListener(EventTypes.OPEN_BUY_FEATURE_POPUP, () => (this.visible = false));
    eventManager.addListener(EventTypes.CLOSE_BUY_FEATURE_POPUP, () => {
      if (!setIsSlotBusy()) this.visible = true;
    });
    this.payTableContainers = [];

    this.createContainers(icons);

    this.parentLayer = layerMessageWindow;
  }

  private createContainers(icons: Icon[]) {
    for (let row = 0; row < SLOTS_PER_REEL_AMOUNT; row++) {
      for (let col = 0; col < REELS_AMOUNT; col++) {
        const index = row * REELS_AMOUNT + col;
        const slot = this.getSlotById(index)!;
        const icon = icons.find((icon) => icon.id === slot.slotId)!;
        const container = new PIXI.Container<MiniPayTable>();
        const rect = new PIXI.Rectangle(
          HIT_AREA_ADJUST,
          (SLOT_RESOURCE_HEIGHT - SLOT_HEIGHT) / 2,
          REEL_WIDTH,
          SLOT_HEIGHT,
        );
        container.x = this.xPosition(col);
        container.y = this.yPosition(col);
        container.interactive = true;
        container.hitArea = rect;
        container.on('pointerdown', () => {
          eventManager.emit(EventTypes.SHOW_PAY_TABLE, index);
        });
        const payTable = new MiniPayTable(index, icon, getCombosDataFromIcon(icon)); // TO DO
        container.addChild(payTable);
        this.payTableContainers.push(container);
        this.addChild(container);
      }
    }
  }

  private xPosition(col: number): number {
    //return col * REEL_WIDTH + CONTAINER_LEFT_ADJUST;
    return (col % 2) * SLOT_WIDTH;
  }

  private yPosition(col: number): number {
    //return row * SLOT_HEIGHT;
    return Math.floor(col / 2) * SLOT_HEIGHT;
  }

  public setSpinResult(spinResult: Icon[]): void {
    spinResult.forEach((icon, index: number) => {
      const container = this.payTableContainers[index]!;
      const payTable = container.children[0]!;
      payTable.setPayTableData(icon, getCombosDataFromIcon(icon));
    });
  }
}

export default MiniPayTableContainer;
