import i18n from 'i18next';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs } from '../../config';
import { FeatureTypes } from '../../consts';
import { setBetAmount, setCoinAmount, setCurrency, setIsBuyFeaturePopupOpened, setSlotConfig } from '../../gql/cache';
import { IBetSettings } from '../../gql/d';
import { getBetsSetting } from '../../gql/fromFragment';
import { BuyFeatureSelectType } from '../../types';
import { normalizeCoins, showCurrency } from '../../utils';
import AutoResizeText from '../components/autoResizeText';
import { SpriteButton } from '../components/button';
import ButtonContainer from '../components/buttonContainer';
import ViewContainer from '../components/container';
import { layerBuyFeaturePopUp } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import { EventTypes, WinStages, eventManager } from '../config';

import { FEATURE_POPUP_MP_BTN_HITAREA_SCALE, FEATURE_POPUP_POSITIONS } from './config';
import {
  betValueStyle,
  buyFeatureBetDescriptionTextStyle,
  buyFeatureNormalCostTextStyle,
  buyFeatureNormalTitleTextStyle,
  buyFeatureSpecialDescriptionTextStyle,
  buyFeatureSpecialTitleTextStyle,
  buyFeatureTitleStyle,
} from './textStyles';

export class BuyFeaturePopup extends ViewContainer {
  private selected: BuyFeatureSelectType;

  private popupBg: TickerSpine<'buy'>;
  private titleText: AutoResizeText;

  private normal: TickerSpine<'buy'>;
  private normalTitleText: AutoResizeText;
  private normalCostText: AutoResizeText;
  private normalContainer: PIXI.Container;
  private normalBtnDisable: boolean;

  private special: TickerSpine<'buy'>;
  private specialTitleText: AutoResizeText;
  private specialCostText: AutoResizeText;
  private specialDescText: AutoResizeText;
  private specialContainer: PIXI.Container;
  private specialBtnDisable: boolean;

  private betDescriptionText: AutoResizeText;
  private betValue: AutoResizeText;
  private betBase: PIXI.Sprite;
  private betContainer: PIXI.Container;

  private minusBtn: SpriteButton;
  private plusBtn: SpriteButton;

  private menuContainer: PIXI.Container;

  private okBtn: SpriteButton;
  private cancelBtn: SpriteButton;

  private baseContainer: PIXI.Container;

  private betAmount: number;

  private betSettings: IBetSettings;

  private linesAmount: number;

  private currency = 'FUN';

  private balance: number;

  constructor() {
    super();
    this.selected = 'Normal';
    this.betSettings = getBetsSetting();
    this.visible = false;
    this.linesAmount = setSlotConfig()?.slotSettings?.globalCoinAmountMultiplier!;
    this.balance = 0;
    this.currency = setCurrency();
    this.betAmount = this.getBetAmount(setBetAmount());

    this.popupBg = this.initPopupBg();
    this.titleText = this.initTitle();
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();
    this.baseContainer = new PIXI.Container();
    this.baseContainer.addChild(this.popupBg, this.titleText, this.cancelBtn, this.okBtn);

    this.normalBtnDisable = false;
    this.normal = this.initNormalBtn();
    this.normalTitleText = this.initNormalTitleText();
    this.normalCostText = this.initNormalCostText();
    this.normalContainer = new ButtonContainer(
      0,
      0,
      FEATURE_POPUP_POSITIONS.MenuContainer.Normal.size.w,
      FEATURE_POPUP_POSITIONS.MenuContainer.Normal.size.h,
      () => {
        if (!this.normalBtnDisable) {
          this.normalContainer.interactive = false;
          this.specialContainer.interactive = true;
          this.normal.state.setAnimation(0, 'btn_nml_push');
          this.normal.state.addAnimation(0, 'btn_nml');
          this.normal.update(0);

          this.special.state.setAnimation(0, 'btn_sp_disable');
          this.special.update(0);
          this.selected = 'Normal';
        }
      },
      () => {
        if (!this.normalBtnDisable && this.selected != 'Normal') {
          this.normal.state.setAnimation(0, 'btn_nml_over');
        }
      },
      () => {
        if (!this.normalBtnDisable && this.selected === 'Normal') {
          this.normal.state.setAnimation(0, 'btn_nml');
        } else if (this.selected === 'Special') {
          this.normal.state.setAnimation(0, 'btn_nml_disable');
        }
      },
      () => {
        this.normal.state.setAnimation(0, 'btn_nml_disable');
      },
    );
    this.normalContainer.addChild(this.normal, this.normalTitleText, this.normalCostText);
    this.normalContainer.position.copyFrom(FEATURE_POPUP_POSITIONS.MenuContainer.Normal.Container);

    this.specialBtnDisable = false;
    this.special = this.initSpecialBtn();
    this.specialTitleText = this.initSpecialTitleText();
    this.specialCostText = this.initSpecialCostText();
    this.specialDescText = this.initSpecialDescriptionText();
    this.specialContainer = new ButtonContainer(
      0,
      0,
      FEATURE_POPUP_POSITIONS.MenuContainer.Special.size.w,
      FEATURE_POPUP_POSITIONS.MenuContainer.Special.size.h,
      () => {
        if (!this.specialBtnDisable) {
          this.specialContainer.interactive = false;
          this.normalContainer.interactive = true;
          this.special.state.setAnimation(0, 'btn_sp_push');
          this.special.state.addAnimation(0, 'btn_sp');
          this.special.update(0);

          this.normal.state.setAnimation(0, 'btn_nml_disable');
          this.normal.update(0);

          this.selected = 'Special';
        }
      },
      () => {
        if (!this.specialBtnDisable && this.selected != 'Special') {
          this.special.state.setAnimation(0, 'btn_sp_ovedr');
        }
      },
      () => {
        if (!this.specialBtnDisable && this.selected === 'Special') {
          this.special.state.setAnimation(0, 'btn_sp');
        } else if (this.selected === 'Normal') {
          this.special.state.setAnimation(0, 'btn_sp_disable');
        }
      },
      () => {
        this.special.state.setAnimation(0, 'btn_sp_disable');
      },
    );
    this.specialContainer.addChild(this.special, this.specialTitleText, this.specialCostText, this.specialDescText);
    this.specialContainer.position.copyFrom(FEATURE_POPUP_POSITIONS.MenuContainer.Special.Container);

    this.betDescriptionText = this.initBetDescriptionText();
    this.betBase = this.initBetBase();
    this.betValue = this.initBetValue();
    this.minusBtn = this.initMinusBtn();
    this.plusBtn = this.initPlusBtn();
    this.betContainer = new PIXI.Container();
    this.betContainer.addChild(this.betBase, this.betDescriptionText, this.betValue, this.minusBtn, this.plusBtn);
    this.betContainer.position.copyFrom(FEATURE_POPUP_POSITIONS.MenuContainer.BetMenu.Container);

    this.baseContainer.position.copyFrom(FEATURE_POPUP_POSITIONS.BaseContainer.Portrait);

    this.menuContainer = new PIXI.Container();
    this.menuContainer.addChild(this.normalContainer, this.specialContainer, this.betContainer);
    this.menuContainer.position.copyFrom(FEATURE_POPUP_POSITIONS.MenuContainer.Portrait);
    this.addChild(this.baseContainer, this.menuContainer);

    this.init();

    this.updateTotalCost();
    this.parentLayer = layerBuyFeaturePopUp;
  }

  private init(): void {
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.closeAllAnimationsInSlot();
      this.visible = true;
      setIsBuyFeaturePopupOpened(true);
      this.okBtn.disable = false;
      this.cancelBtn.disable = false;

      this.normal.state.setAnimation(0, 'btn_nml');
      this.special.state.setAnimation(0, 'btn_sp_disable');
      this.specialContainer.interactive = true;
      this.normalContainer.interactive = true;

      this.handleDisable();
    });
    eventManager.on(EventTypes.UPDATE_BET, () => {
      this.betAmount = this.getBetAmount(setBetAmount());
      this.updateBets();
      this.handleDisable();
    });
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, () => {
      this.visible = false;
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP);
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
      setIsBuyFeaturePopupOpened(false);
    });
    eventManager.on(EventTypes.BUY_FEATURE_CONFIRMED, () => {
      this.visible = false;
      setIsBuyFeaturePopupOpened(false); //don't change order
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP);
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
    });
    eventManager.on(EventTypes.UPDATE_USER_BALANCE, (balance) => {
      this.balance = balance!.amount / 100;
      this.handleDisable();
    });
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));
  }

  private initPopupBg(): TickerSpine {
    const popupBg = new TickerSpine('buy');
    popupBg.update(0);
    popupBg.state.setAnimation(0, 'base', true);
    return popupBg;
  }

  private initTitle(): AutoResizeText {
    const title = new AutoResizeText(i18n.t('buyFeature.title'), buyFeatureTitleStyle);
    title.position.copyFrom(FEATURE_POPUP_POSITIONS.BaseContainer.Title);
    title.anchor.set(0.5, 0.5);

    return title;
  }

  private initNormalBtn(): TickerSpine {
    const spine = new TickerSpine('buy');
    spine.update(0);
    spine.state.setAnimation(0, 'btn_nml', true);
    spine.position.copyFrom(FEATURE_POPUP_POSITIONS.MenuContainer.Normal.Base);
    return spine;
  }
  private initNormalTitleText(): AutoResizeText {
    const text = new AutoResizeText(i18n.t('buyFeature.normalBait'), buyFeatureNormalTitleTextStyle);
    text.position.copyFrom(FEATURE_POPUP_POSITIONS.MenuContainer.Normal.Title);
    text.anchor.set(0.5, 0.5);
    return text;
  }
  private initNormalCostText(): AutoResizeText {
    const text = new AutoResizeText(
      `${formatNumber({
        currency: this.currency,
        value: normalizeCoins(this.getBetValue()) * 30, //TO DO
        showCurrency: showCurrency(this.currency),
      })}`,
      buyFeatureNormalCostTextStyle,
    );
    text.position.copyFrom(FEATURE_POPUP_POSITIONS.MenuContainer.Normal.Cost);
    text.anchor.set(0.5, 0.5);
    return text;
  }

  private initSpecialBtn(): TickerSpine {
    const spine = new TickerSpine('buy');
    spine.update(0);
    spine.state.setAnimation(0, 'btn_sp', true);
    spine.position.copyFrom(FEATURE_POPUP_POSITIONS.MenuContainer.Special.Base);
    return spine;
  }

  private initSpecialTitleText(): AutoResizeText {
    const betText = new AutoResizeText(i18n.t('buyFeature.specialBait'), buyFeatureSpecialTitleTextStyle);
    betText.position.copyFrom(FEATURE_POPUP_POSITIONS.MenuContainer.Special.Title);
    betText.anchor.set(0.5, 0.5);
    return betText;
  }

  private initSpecialCostText(): AutoResizeText {
    const betValue = new AutoResizeText(
      `${formatNumber({
        currency: this.currency,
        value: normalizeCoins(this.getBetValue()),
        showCurrency: showCurrency(this.currency),
      })}`,
      buyFeatureNormalCostTextStyle,
    );
    betValue.position.copyFrom(FEATURE_POPUP_POSITIONS.MenuContainer.Special.Cost);
    betValue.anchor.set(0.5, 0.5);
    return betValue;
  }

  private initSpecialDescriptionText(): AutoResizeText {
    const betText = new AutoResizeText(i18n.t('buyFeature.specialDesc'), buyFeatureSpecialDescriptionTextStyle);
    betText.position.copyFrom(FEATURE_POPUP_POSITIONS.MenuContainer.Special.Description);
    betText.anchor.set(0.5, 0.5);
    return betText;
  }

  private initBetDescriptionText(): AutoResizeText {
    const betText = new AutoResizeText(i18n.t('buyFeature.betPerGame'), buyFeatureBetDescriptionTextStyle);
    betText.position.copyFrom(FEATURE_POPUP_POSITIONS.MenuContainer.BetMenu.BetTitle);
    betText.anchor.set(0.5, 0.5);
    return betText;
  }
  private initBetBase(): PIXI.Sprite {
    const base = new PIXI.Sprite(PIXI.Texture.from('buy_bet'));
    base.pivot.x = 403 / 2;
    base.pivot.y = 94 / 2;
    base.scale.set(504 / 403);

    base.position.copyFrom(FEATURE_POPUP_POSITIONS.MenuContainer.BetMenu.BetBase);
    return base;
  }

  private initBetValue(): AutoResizeText {
    const betValue = new AutoResizeText(
      `${formatNumber({
        currency: this.currency,
        value: normalizeCoins(this.getBetValue()),
        showCurrency: showCurrency(this.currency),
      })}`,
      betValueStyle,
    );
    betValue.position.copyFrom(FEATURE_POPUP_POSITIONS.MenuContainer.BetMenu.BetValue);
    betValue.anchor.set(0.5, 0.5);

    return betValue;
  }

  private initMinusBtn(): SpriteButton {
    const minusBtn = new SpriteButton(
      {
        default: 'buy_btn_m',
        hover: 'buy_btn_m_over',
        press: 'buy_btn_m_push',
        disable: 'buy_btn_m_disable',
      },
      () => {
        if (this.betSettings.bets[this.betAmount - 1]! > this.betSettings!.minBet) {
          // eslint-disable-next-line no-plusplus
          this.betAmount--;
          setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
          this.updateBets();
          this.handleDisable();
          setBetAmount(setCoinAmount() * setSlotConfig().lineSet.coinAmountMultiplier);
          AudioApi.play({ type: ISongs.SFX_UI_BetChange });
        }
      },
    );

    minusBtn.position.copyFrom(FEATURE_POPUP_POSITIONS.MenuContainer.BetMenu.MinusBtn);
    minusBtn.anchor.set(0.5, 0.5);

    const btnHitArea = {
      w: minusBtn.width * FEATURE_POPUP_MP_BTN_HITAREA_SCALE,
      h: minusBtn.height * FEATURE_POPUP_MP_BTN_HITAREA_SCALE,
    };
    minusBtn.hitArea = new PIXI.Rectangle(-btnHitArea.w / 2, -btnHitArea.h / 2, btnHitArea.w, btnHitArea.h);

    return minusBtn;
  }

  private initPlusBtn(): SpriteButton {
    const plusBtn = new SpriteButton(
      {
        default: 'buy_btn_p',
        hover: 'buy_btn_p_over',
        press: 'buy_btn_p_push',
        disable: 'buy_btn_p_disable',
      },
      () => {
        if (this.betSettings.bets[this.betAmount - 1]! < this.betSettings!.maxBet) {
          this.betAmount++;
          setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
          this.updateBets();
          this.handleDisable();
          setBetAmount(setCoinAmount() * setSlotConfig().lineSet.coinAmountMultiplier);
          AudioApi.play({ type: ISongs.SFX_UI_BetChange });
        }
      },
    );

    plusBtn.position.copyFrom(FEATURE_POPUP_POSITIONS.MenuContainer.BetMenu.PlusBtn);
    plusBtn.anchor.set(0.5, 0.5);

    const btnHitArea = {
      w: plusBtn.width * FEATURE_POPUP_MP_BTN_HITAREA_SCALE,
      h: plusBtn.height * FEATURE_POPUP_MP_BTN_HITAREA_SCALE,
    };
    plusBtn.hitArea = new PIXI.Rectangle(-btnHitArea.w / 2, -btnHitArea.h / 2, btnHitArea.w, btnHitArea.h);
    return plusBtn;
  }

  private initCancelBtn(): SpriteButton {
    const cancelBtn = new SpriteButton(
      {
        default: 'gamble_btn_back',
        hover: 'gamble_btn_back_over',
        press: 'gamble_btn_back_push',
        disable: 'gamble_btn_back_disable',
      },
      () => {
        cancelBtn.disable = false;
        AudioApi.play({ type: ISongs.SFX_UI_Close });
        this.visible = false;
        setIsBuyFeaturePopupOpened(false);
        eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
        eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP);
        eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
      },
    );

    cancelBtn.position.copyFrom(FEATURE_POPUP_POSITIONS.BaseContainer.CancelBtn);
    cancelBtn.anchor.set(0.5);

    return cancelBtn;
  }

  private initOkBtn(): SpriteButton {
    const okBtn = new SpriteButton(
      {
        default: 'gamble_btn_ok',
        hover: 'gamble_btn_ok_over',
        press: 'gamble_btn_ok_push',
        disable: 'gamble_btn_ok_disable',
      },
      () => {
        okBtn.disable = false;
        AudioApi.play({ type: ISongs.SFX_UI_General });
        eventManager.emit(
          EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP,
          this.getTotalCost(this.selected),
          this.betSettings.bets[this.betAmount]!,
          this.selected === 'Special',
        );
      },
    );
    okBtn.position.copyFrom(FEATURE_POPUP_POSITIONS.BaseContainer.OkBtn);
    okBtn.anchor.set(0.5);

    return okBtn;
  }

  private getBetAmount(betAmount: number): number {
    return this.betSettings!.bets.findIndex((bet) => bet === betAmount / this.linesAmount) + 1;
  }

  private updateBets(): void {
    this.betValue.text = `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetValue()),
      showCurrency: showCurrency(this.currency),
    })}`;
    this.updateTotalCost();
  }

  private updateTotalCost(): void {
    this.normalCostText.text = this.getTotalCost('Normal');
    this.specialCostText.text = this.getTotalCost('Special');
  }

  private getTotalCost(index: BuyFeatureSelectType): string {
    return `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetMultiplier() * this.getCoinAmount(index)),
      showCurrency: showCurrency(this.currency),
    })}`;
  }

  private getBetValue(): number {
    return this.linesAmount * (this.betSettings!.bets[this.betAmount - 1] || 1);
  }

  private getBetMultiplier(): number {
    return this.betSettings!.bets[this.betAmount - 1] || 1;
  }

  // TO DO
  private getCoinAmount(index: BuyFeatureSelectType): number {
    const bonuses = setSlotConfig()?.slotSettings?.buyFeaturePackages;
    const bonus = bonuses?.filter(
      (bonus) =>
        bonus?.packageId === FeatureTypes.BUY_FEATURE_FREE_SPINS_NORMAL ||
        bonus?.packageId === FeatureTypes.BUY_FEATURE_FREE_SPINS_SPECIAL,
    )[0] ?? {
      coinAmountMultiplier: 0,
    };

    //test code
    if (index === 'Special') {
      return 2000;
    } else if (index === 'Normal') {
      return 500;
    }
    return bonus.coinAmountMultiplier;
  }

  private normalBtnUpdate() {
    if (this.normalBtnDisable) {
      this.normalContainer.interactive = false;
      this.normal.state.setAnimation(0, 'btn_nml_disable');
    } else {
      if (this.selected != 'Normal') {
        this.normal.state.setAnimation(0, 'btn_nml_disable');
        this.normalContainer.interactive = true;
      } else {
        this.normal.state.setAnimation(0, 'btn_nml');
      }
    }
  }

  private specialBtnUpdate() {
    if (this.specialBtnDisable) {
      this.specialContainer.interactive = false;
      this.special.state.setAnimation(0, 'btn_sp_disable');
    } else {
      if (this.selected != 'Special') {
        this.special.state.setAnimation(0, 'btn_sp_disable');
        this.specialContainer.interactive = true;
      } else {
        this.special.state.setAnimation(0, 'btn_sp');
      }
    }
  }

  private handleDisable(): void {
    const bet = this.betSettings.bets[this.betAmount - 1];

    this.minusBtn.disable = bet === this.betSettings!.minBet;

    this.plusBtn.disable = bet === this.betSettings!.maxBet;

    this.okBtn.disable = this.balance < normalizeCoins(this.getBetMultiplier() * this.getCoinAmount(this.selected));

    this.normalBtnDisable = this.balance < normalizeCoins(this.getBetMultiplier() * this.getCoinAmount('Normal'));
    this.specialBtnDisable = this.balance < normalizeCoins(this.getBetMultiplier() * this.getCoinAmount('Special'));

    this.normalBtnUpdate();
    this.specialBtnUpdate();
  }

  private closeAllAnimationsInSlot() {
    eventManager.emit(EventTypes.SET_WIN_VISIBILITY, WinStages.None);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
  }

  private applicationResize = (width: number, height: number): void => {
    this.handleResize(width, height);
  };

  private handleResize(width: number, height: number): void {
    if (height > width) {
      this.x = 0;
      this.y = 0;
      this.scale.set(FEATURE_POPUP_POSITIONS.scale.x);
      this.scale.set(1);
    } else {
      this.x = FEATURE_POPUP_POSITIONS.landscape.x;
      this.y = FEATURE_POPUP_POSITIONS.landscape.y;
      this.scale.set(FEATURE_POPUP_POSITIONS.scale.x);
    }
  }
}
