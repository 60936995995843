/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs, config } from '../../config';
import { BonusStatus, GameMode } from '../../consts';
import {
  setAutoSpinsAmount,
  setAutoSpinsLeft,
  setAutoSpinsStartBalance,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setGameMode,
  setIsAutoSpins,
  setIsBuyFeaturePopupOpened,
  setIsBuyFeatureSpin,
  setIsContinueAutoSpinsAfterFeature,
  setIsFishingUserActionWait,
  setIsFreeSpinsWin,
  setIsInTransition,
  setIsOpenedMessageBanner,
  setIsOpeningScenePlaying,
  setIsSlotBusy,
  setIsSpinInProgress,
  setIsSpinShortCut,
  setIsStopOnAnyWin,
  setIsStopOnBalanceDecrease,
  setIsStopOnBalanceIncrease,
  setIsStopOnFeatureWin,
  setIsStopOnWinExceeds, //  setIsWheelSpinning,
  setLastRegularWinAmount,
  setProgress,
  setSlotConfig,
  setStopOnBalanceDecrease,
  setStopOnBalanceIncrease,
  setStopOnWinExceeds,
  setStressful,
  setUserBalance,
  setWinAmount,
} from '../../gql/cache';
import { placeBetGql } from '../../gql/mutation';
import { configGql, getAutoSpinsGql, getBalanceGql, getBetAmountGql, isStoppedGql } from '../../gql/query';
import SlotMachine from '../../slotMachine';
import { EventTypes, SlotMachineState, eventManager } from '../../slotMachine/config';
import { ISettledBet } from '../../types';
import {
  canPressSpin,
  getReelPositions,
  isBaseGameMode,
  isFishingChallengeMode,
  isFreeSpinsMode,
  saveReelPosition,
  showCurrency,
} from '../../utils';

const Spin: React.FC = () => {
  const { data } = useQuery(configGql);
  const { isTurboSpin } = data!;
  const { data: dataBet } = useQuery(getBetAmountGql);
  const { slotId: slotId } = useReactiveVar(setSlotConfig);
  const isFreeSpinsWin = useReactiveVar(setIsFreeSpinsWin);
  const balance = useReactiveVar(setUserBalance);
  const { data: userBalance } = useQuery(getBalanceGql);

  const progress = useReactiveVar(setProgress);
  const { data: dataSlotStopped } = useQuery(isStoppedGql);

  const gameMode = useReactiveVar(setGameMode);
  const balanceAmount = balance?.amount || 0;
  const [winThreeTimes, setGameHistory] = useState([false, false, false, false, false]);

  const { data: autoSpins } = useQuery(getAutoSpinsGql);
  const { isAutoSpins } = autoSpins!;

  const stressful = useReactiveVar(setStressful);

  const isFreeSpinModeOnTotalWinBannerStep: () => boolean = () =>
    isFreeSpinsMode(setGameMode()) &&
    setCurrentBonus().state === BonusStatus.OPEN &&
    setCurrentBonus().rounds === setCurrentBonus().roundsPlayed;

  const [fnGet, { client }] = useMutation(placeBetGql, {
    async onCompleted({ placeBet }) {
      window.dispatchEvent(new CustomEvent('placeBetCompleted'));
      client.writeQuery({
        query: getBalanceGql,
        data: {
          ...userBalance,
          balance: placeBet.balance.placed,
        },
      });
      SlotMachine.getInstance().setResult(placeBet as ISettledBet);
      if (SlotMachine.getInstance().isStopped) {
        SlotMachine.getInstance().spin(isTurboSpin);
      }
      const callBack = () => {
        const win = placeBet!.winCoinAmount;
        const lastThreeSpins = [...winThreeTimes.slice(1), !!win];
        setGameHistory(lastThreeSpins);

        client.writeQuery({
          query: getBalanceGql,
          data: {
            ...userBalance,
            balance: placeBet.balance.settled,
          },
        });
        saveReelPosition(getReelPositions(placeBet));
      };
      SlotMachine.getInstance().setStopCallback(callBack.bind(this));
    },
  });

  const onSpin = useCallback(
    (isTurboSpin?: boolean) => {
      const spinState = SlotMachine.getInstance().state;
      SlotMachine.getInstance().spin(isTurboSpin);
      if (spinState === SlotMachineState.IDLE) {
        if (isFreeSpinsMode(setGameMode())) return;

        eventManager.emit(
          EventTypes.UPDATE_WIN_VALUE,
          formatNumber({ currency: setCurrency(), value: 0, showCurrency: showCurrency(setCurrency()) }),
        );
        setWinAmount(0);
        setLastRegularWinAmount(0);
        if (setIsAutoSpins()) setAutoSpinsLeft(setAutoSpinsLeft() - 1);
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: false,
          },
        });

        fnGet({
          variables: {
            input: {
              slotId,
              coinAmount: setCoinAmount(),
              coinValue: setCoinValue(),
            },
          },
        });

        setIsSpinInProgress(true);
        setIsSlotBusy(true);
        AudioApi.stop({ type: ISongs.SFX_UI_Close });
        AudioApi.play({ type: ISongs.XT005S_spin_start });
        AudioApi.play({ type: ISongs.XT005S_spin_loop, stopPrev: true, loop: true });
      } else {
        if (SlotMachine.getInstance().state === SlotMachineState.STOP) {
          client.writeQuery({
            query: isStoppedGql,
            data: {
              isSlotStopped: true,
            },
          });
        }
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    [dataBet?.betAmount, fnGet, slotId],
  );

  useEffect(() => {
    const buyFeature = (_id: string) => {
      setIsBuyFeatureSpin(true);
      SlotMachine.getInstance().spin(isTurboSpin);
      eventManager.emit(
        EventTypes.UPDATE_WIN_VALUE,
        formatNumber({ currency: setCurrency(), value: 0, showCurrency: showCurrency(setCurrency()) }),
      );
      setWinAmount(0);
      setLastRegularWinAmount(0);
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: false,
        },
      });
      fnGet({
        variables: {
          input: {
            slotId,
            coinAmount: setCoinAmount(),
            coinValue: setCoinValue(),
            //userBonusId: id,
          },
        },
      });
      setIsSpinInProgress(true);
      setIsSlotBusy(true);
      AudioApi.play({ type: ISongs.XT005S_spin_start });
    };

    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, buyFeature);
    return () => {
      eventManager.removeListener(EventTypes.START_BUY_FEATURE_ROUND, buyFeature);
    };
  }, [onSpin, isTurboSpin]);

  useEffect(() => {
    const freeSpin = () => {
      const spinState = SlotMachine.getInstance().state;
      SlotMachine.getInstance().spin(isTurboSpin);
      if (spinState != SlotMachineState.IDLE) {
        return;
      }

      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: false,
        },
      });
      fnGet({
        variables: {
          input: {
            slotId,
            coinAmount: setCoinAmount(),
            coinValue: setCoinValue(),
            //userBonusId: setCurrentBonus().id,
          },
        },
      });
      setIsSpinInProgress(true);
      setIsSlotBusy(true);
      AudioApi.play({ type: ISongs.XT005S_wheel_spin_push });
    };
    eventManager.on(EventTypes.NEXT_FREE_SPINS_ROUND, freeSpin);
    return () => {
      eventManager.removeListener(EventTypes.NEXT_FREE_SPINS_ROUND, freeSpin);
    };
  }, [onSpin, isTurboSpin]);

  useEffect(() => {
    const gambleSpin = () => {
      const spinState = SlotMachine.getInstance().state;
      SlotMachine.getInstance().spin(isTurboSpin);
      if (spinState != SlotMachineState.IDLE) {
        return;
      }

      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: false,
        },
      });
      fnGet({
        variables: {
          input: {
            slotId,
            coinAmount: setCoinAmount(),
            coinValue: setCoinValue(),
            //userBonusId: setCurrentBonus().id,
          },
        },
      });
      setIsSpinInProgress(true);
      setIsSlotBusy(true);
      //AudioApi.play({ type: ISongs.XT005S_spin_start });
    };
    eventManager.on(EventTypes.GAMBLE_SPIN, gambleSpin);
    return () => {
      eventManager.removeListener(EventTypes.GAMBLE_SPIN, gambleSpin);
    };
  }, [onSpin, isTurboSpin]);

  useEffect(() => {
    const scalingSpin = () => {
      const spinState = SlotMachine.getInstance().state;
      SlotMachine.getInstance().scalingSpin();
      if (spinState != SlotMachineState.IDLE) {
        return;
      }

      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: false,
        },
      });
      fnGet({
        variables: {
          input: {
            slotId,
            coinAmount: setCoinAmount(),
            coinValue: setCoinValue(),
            //userBonusId: setCurrentBonus().id,
          },
        },
      });
      setIsSpinInProgress(true);
      setIsSlotBusy(true);
      //      AudioApi.play({ type: ISongs.XT005S_spin_start });
    };
    eventManager.on(EventTypes.SCALING_SPIN, scalingSpin);
    return () => {
      eventManager.removeListener(EventTypes.SCALING_SPIN, scalingSpin);
    };
  }, [onSpin, isTurboSpin]);

  const checkAutoSpinSettings = useCallback(() => {
    if (setIsAutoSpins()) {
      const autoSpinsLeft = setAutoSpinsLeft() <= 0;
      const bonus = setIsStopOnFeatureWin() && setCurrentBonus().state === BonusStatus.OPEN;
      const stopOnWin =
        setIsStopOnAnyWin() && (setLastRegularWinAmount() > 0 || setCurrentBonus().state === BonusStatus.OPEN);

      const stopOnWinExceeds = setIsStopOnWinExceeds() && setLastRegularWinAmount() >= setStopOnWinExceeds();

      const balanceIncrease =
        setIsStopOnBalanceIncrease() &&
        balanceAmount &&
        setStopOnBalanceIncrease() * setCoinValue() <= balanceAmount - setAutoSpinsStartBalance();

      const balanceDecrease =
        setIsStopOnBalanceDecrease() &&
        balanceAmount &&
        setStopOnBalanceDecrease() * setCoinValue() <= setAutoSpinsStartBalance() - balanceAmount;

      if (autoSpinsLeft || bonus || stopOnWin || stopOnWinExceeds || balanceIncrease || balanceDecrease) {
        setIsAutoSpins(false);
        if (!setIsSlotBusy()) {
          eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
        }
      } else {
        onSpin(isTurboSpin);
      }
    }
  }, [balanceAmount, onSpin, isTurboSpin]);

  useEffect(() => {
    if (isAutoSpins && setIsFreeSpinsWin()) {
      if (setIsStopOnFeatureWin()) {
        setIsContinueAutoSpinsAfterFeature(false);
        setAutoSpinsLeft(0);
      } else {
        setIsContinueAutoSpinsAfterFeature(true);
      }
      setIsAutoSpins(false);
    }
  }, [isFreeSpinsWin, setIsContinueAutoSpinsAfterFeature()]);

  const onSpinButtonClick = useCallback(() => {
    if (isBaseGameMode(setGameMode()) && setIsFreeSpinsWin()) {
      return;
    }

    if (setIsOpenedMessageBanner()) {
      eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
      return;
    }

    if (setIsFishingUserActionWait()) {
      if (setGameMode() === GameMode.SCALING) {
        eventManager.emit(EventTypes.SCALING_BANNER_SKIP);
      }
      if (setGameMode() === GameMode.FISH_CHALLENGE) {
        eventManager.emit(EventTypes.FISHING_SKIP_ROULETTE);
      }
      return;
    }

    if (isAutoSpins) {
      checkAutoSpinSettings();
      return;
    }
    if (isFishingChallengeMode(setGameMode())) {
      eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND);
      return;
    }
    if (setGameMode() === GameMode.FISH_GAMBLE || setGameMode() === GameMode.FISH_GAMBLE_SELECT) {
      return;
    }
    if (setGameMode() === GameMode.SCALING) {
      eventManager.emit(EventTypes.SCALING_SPIN);
      return;
    }

    onSpin(isTurboSpin);
  }, [isAutoSpins, isTurboSpin, onSpin]);

  const useHandleSpaceSpin = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 32) {
        e.preventDefault();
        e.stopPropagation();

        if (stressful.show) return;

        if (
          !canPressSpin({
            gameMode,
            isFreeSpinsWin: setIsFreeSpinsWin(),
            isSpinInProgress: setIsSpinInProgress(),
            isSlotBusy: setIsSlotBusy(),
            isSlotStopped: dataSlotStopped?.isSlotStopped ?? false,
            isOpenedMessageBanner: setIsOpenedMessageBanner(),
            isInTransition: setIsInTransition(),
            isBuyFeaturePopupOpened: setIsBuyFeaturePopupOpened(),
            isOpeningScenePlaying: setIsOpeningScenePlaying(),
            isSpinShortCut: setIsSpinShortCut(),
            isAutoPlay: false,
            isWaitingUserAction: setIsFishingUserActionWait(),
            //isWheelSpinning: setIsWheelSpinning(),
          })
        ) {
          return;
        }

        if (setIsOpenedMessageBanner()) {
          eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
          return;
        }

        if (setIsFishingUserActionWait()) {
          if (setGameMode() === GameMode.SCALING) {
            eventManager.emit(EventTypes.SCALING_BANNER_SKIP);
          }
          if (setGameMode() === GameMode.FISH_CHALLENGE) {
            eventManager.emit(EventTypes.FISHING_SKIP_ROULETTE);
          }
          return;
        }

        if (isAutoSpins) {
          checkAutoSpinSettings();
          return;
        }
        if (setGameMode() === GameMode.FISH_GAMBLE || setGameMode() === GameMode.FISH_GAMBLE_SELECT) {
          return;
        }
        if (setGameMode() === GameMode.SCALING) {
          eventManager.emit(EventTypes.SCALING_SPIN);
          return;
        }
        if (isFishingChallengeMode(setGameMode())) {
          eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND);
          return;
        }
        if (progress.wasLoaded && !isFreeSpinModeOnTotalWinBannerStep()) {
          onSpin(isTurboSpin);
        }
      }
    },
    [
      gameMode,
      isAutoSpins,
      dataSlotStopped?.isSlotStopped,
      progress.wasLoaded,
      checkAutoSpinSettings,
      onSpin,
      isTurboSpin,
      stressful,
    ],
  );

  useEffect(() => {
    window.addEventListener('keydown', useHandleSpaceSpin);
    return () => window.removeEventListener('keydown', useHandleSpaceSpin);
  }, [useHandleSpaceSpin]);

  useEffect(() => {}, [winThreeTimes]);

  useEffect(() => {
    let id: number;
    if (!setIsFreeSpinsWin() && setIsContinueAutoSpinsAfterFeature()) {
      setIsAutoSpins(true);
      setIsContinueAutoSpinsAfterFeature(false);
    }
    if (dataSlotStopped?.isSlotStopped) {
      id = window.setTimeout(
        () => {
          checkAutoSpinSettings();
        },
        setAutoSpinsLeft() === setAutoSpinsAmount() ? 0 : config.autoplay.timeOut,
      );
    }
    return () => clearTimeout(id);
  }, [isAutoSpins, isFreeSpinsWin, checkAutoSpinSettings, dataSlotStopped?.isSlotStopped]);

  useEffect(() => {
    eventManager.on(EventTypes.TOGGLE_SPIN, () => {
      onSpinButtonClick();
    });

    return () => {
      eventManager.removeListener(EventTypes.TOGGLE_SPIN);
    };
  }, [onSpinButtonClick]);

  return null;
};

export default Spin;
